import { CertificateTestimonialProps } from "../CertificateTestimonial";

export const defaultTestimonialData: CertificateTestimonialProps = {
  testimonial:
    " I never thought that I could have the opportunity to learn with courses from such prestigious universities. There are no limits on edX. You can certify your knowledge in everything you are willing to learn and that is amazing.",
  attribution: "edX Learner",
};

export const defaultTabTestimonialData: CertificateTestimonialProps = {
  ...defaultTestimonialData,
  title: "Hear from learners",
};
