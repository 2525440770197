"use client";
import React, { useEffect, useState } from "react";
import getCourseRunEnrollButtonText from "@/lib/course/utils/getCourseRunEnrollButtonText";
import hasCourseRunStarted from "@/lib/course/utils/hasCourseRunStarted";
import {
  AUTH_DOMAIN,
  LMS_COURSE_DOMAIN,
  LMS_DOMAIN,
} from "@/lib/course/utils/constants";
import { cn } from "@/lib/utils";
import {
  CourseEnrollButtonClickEvents,
  CourseEnrollButtonProps,
} from "./types";
import { Alert } from "@/components/ui/alert";
import { isDateInPast } from "@/lib/date";
import isUserLoggedIn from "@/lib/auth/isUserLoggedIn";
import EnterpriseEnrollmentInformModal from "./components/EnterpriseEnrollmentInformModal";
import { createEnrollment } from "@/lib/partner-api/createEnrollment";
import useUserEnrollments from "../CourseContext/hooks/useUserEnrollments";
import useUserEntitlements from "../CourseContext/hooks/useUserEntitlements";
import {
  enrollButtonClickEvent,
  EnrollmentButtonClickEventData,
  numberOr,
} from "@/lib/segment";
import { PacingType } from "@/lib/program/types";
import { useParams, useSearchParams } from "next/navigation";

import {
  getBrandString,
  getCorrelationID,
  getCourseSubject,
  getVariantCategoryFromUrl,
  hyphenateForTagular,
} from "@/utils/tagular/helpers";
import { EVENTS } from "@/hooks/eventing/types/events";
import { ConversionTracked } from "@/hooks/eventing/types/core.conversions.ConversionTracked.v2";
import makeEvent from "@/hooks/eventing/makeEvent";
import isUserEnrolled from "@/lib/course/utils/isUserEnrolled";
import useIsMobile from "@/hooks/useIsMobile";
import { Skeleton } from "@/components/ui/skeleton";
import useLeadWithPaid from "@/monarch/rules/leadWithPaid/useLeadWithPaid";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import { ElementType } from "@/constants/tagular/main";
import useElementEvents from "@/hooks/eventing/useElementEvents";

const enrollButtonBaseStyles =
  "inline-block bg-brand text-base text-white text-center content-center items-center min-w-full md:min-w-32 py-2 px-4 hover:bg-brand-dark justify-center disabled:opacity-25";

const getTrackSelectionUrl = (
  category: string,
  courseSlug: string,
  courseRunKey: string,
  useNewTrackSelect: boolean = false,
) => {
  if (useNewTrackSelect) {
    return `/track-select/${category}/${courseSlug}/?courseRunKey=${encodeURIComponent(courseRunKey)}`;
  }
  const trackSelectionUrl = `${LMS_DOMAIN}/course_modes/choose/`;
  return `${trackSelectionUrl}${encodeURIComponent(courseRunKey)}`;
};

function CourseEnrollButton({
  id,
  buttonClass = "",
  className = "",
  courseRun,
  course,
  emailOptIn = false,
  children,
  isSelfHiding,
}: CourseEnrollButtonProps) {
  const [disabled, setDisabled] = useState(false);
  const [isOpenEnterpriseModal, setIsOpenEnterpriseModal] = useState(false);
  const { userEnrollments, isUserEnrolled: isUserEnrolledInCourse } =
    useUserEnrollments();
  const { isUserEntitled } = useUserEntitlements();
  const isLoggedIn = isUserLoggedIn();
  const loggedOutLinkRef = React.useRef<HTMLButtonElement | null>(null);
  const hasClickEvents = React.useRef<CourseEnrollButtonClickEvents>({});
  const { category, courseSlug } = useParams();
  const searchParams = useSearchParams();
  const hasSearchParams = searchParams.toString().length > 0;
  const useNewTrackSelect = !!(searchParams?.get("newtrackselect") === "true");
  const isMobile = useIsMobile();

  const { value, isLoading } = useLeadWithPaid(course);

  const {
    conversionType,
    linkDirectToPaymentPage,
    auditCourseLabel,
    enrollCtaLabel,
  } = value ?? {};
  // A course can have multiple course runs, we need to check the enrollment status
  // of the specific run here
  const isUserEnrolledInRun = isUserEnrolled(
    userEnrollments || [],
    courseRun.key,
  );

  const enrollButtonText = getCourseRunEnrollButtonText(
    {
      enrollmentStart: courseRun.enrollmentStart,
      enrollmentEnd: courseRun.enrollmentEnd,
      end: courseRun.end,
    },
    isUserEnrolledInRun || false,
  );

  const ctaLabel =
    enrollButtonText === "Enroll" && enrollCtaLabel
      ? enrollCtaLabel
      : undefined;

  const webElementText = ctaLabel ?? enrollButtonText;

  const webElement: ElementClicked["webElement"] = {
    name: "hero-cta",
    elementType: ElementType.Button,
    htmlId: "advance-free-cta",
    location: "hero",
    text: hyphenateForTagular(webElementText),
    position: "1",
  };

  const { elementClicked } = useElementEvents({
    webElement,
  });

  let isFromSearchPage = false;
  let isFromCoursePage = false;

  if (typeof document !== "undefined" && document.referrer) {
    const referrerUrl = document.referrer;
    isFromSearchPage = referrerUrl.includes("/search");
    isFromCoursePage = referrerUrl.includes("/courses");
  }

  const shouldHaveQueryParams =
    (isFromSearchPage && hasSearchParams) || isFromCoursePage;

  const enrollmentClickEventPayload = (): EnrollmentButtonClickEventData => ({
    // Event attributes for all scenarios
    label: courseRun?.key,
    userEnrolled: Boolean(isUserEnrolledInRun),
    userLoggedIn: isLoggedIn,
    userEntitled: isUserEntitled,
    link: !isLoggedIn ? courseRun?.enrollmentUrl : {},
    emailOptIn: emailOptIn,
    category: "navigation",
    objectId: `course-${courseRun?.uuid}`,
    component: "enroll-button-link",
    subject: getCourseSubject(course),
    url: window?.location?.href,
    mobile: isMobile.toString(),
    activeCourseRunKey: courseRun?.key,
    courseUuid: courseRun?.uuid,
    isSelfPaced: courseRun?.pacingType === PacingType.SELFPACED,

    // Event attributes for from Search Page queried and Course Page
    ...(shouldHaveQueryParams && {
      index: searchParams.get("index") ?? "product",
      queryId: searchParams.get("queryId") ?? undefined,
      position: numberOr(searchParams.get("position"), 0),
    }),
  });

  const updateLoggedOutLinkRef = (clonedElement: HTMLButtonElement | null) => {
    loggedOutLinkRef.current = clonedElement;
  };

  const attachSegmentEventListener = (reAttachSegmentEvent = false) => {
    if (loggedOutLinkRef.current) {
      if (
        !hasClickEvents.current[id] ||
        hasClickEvents.current["emailOptIn"] !== emailOptIn
      ) {
        enrollButtonClickEvent(
          {
            domElement: loggedOutLinkRef.current,
            ...(reAttachSegmentEvent && { reAttachSegmentEvent }),
            ...enrollmentClickEventPayload(),
          },
          reAttachSegmentEvent ? updateLoggedOutLinkRef : undefined,
        );
        hasClickEvents.current[id] = true;
        hasClickEvents.current["emailOptIn"] = emailOptIn;
      }
    }
  };

  useEffect(() => {
    // For any instance of the link that renders later
    const observer = new MutationObserver(() => {
      if (loggedOutLinkRef.current) {
        attachSegmentEventListener();
        observer.disconnect();
      }
    });

    if (loggedOutLinkRef.current) {
      attachSegmentEventListener();
    } else {
      observer.observe(document.body, { childList: true, subtree: false });
    }
    return () => observer.disconnect();
  }, [loggedOutLinkRef.current, id]);

  useEffect(() => {
    // Re-attach the event listener whenever emailOptIn is toggled
    if (!loggedOutLinkRef.current) return;
    attachSegmentEventListener(true);
  }, [emailOptIn]);

  //TODO: Replace with api call to get enterprise user data
  const isEnterpriseUser = false;

  const courseStarted = courseRun.start
    ? hasCourseRunStarted(courseRun.start)
    : false;

  const { isEnrollable: isCourseEnrollable } = courseRun;

  const isCourseArchived = courseRun.end ? isDateInPast(courseRun.end) : false;

  const hideButton =
    isSelfHiding &&
    (!isCourseEnrollable || isCourseArchived || isUserEnrolledInRun);

  if (hideButton) {
    return null;
  }

  const openEnterpriseEnrollmentModal = () => {
    setIsOpenEnterpriseModal(true);
  };

  const skus = [
    courseRun.seats.find((seat) => seat.type === "verified")?.sku,
    courseRun.seats.find((seat) => seat.type === "professional")?.sku,
  ];

  const commerceCoodrinatorUrl = `https://commerce-coordinator.edx.org/lms/payment_page_redirect/?sku=${skus[0] ?? skus[1]}&course_run_key=${courseRun.key}`;

  const triggerConversionEvent =
    (customName: CourseEnrollButtonProps["conversionType"] = "enroll") =>
    () => {
      let productBrand = "unknown";

      if (courseRun.instructors.length >= 1) {
        productBrand = hyphenateForTagular(
          courseRun.instructors[0].partnerName || productBrand,
        );
      }
      const { variant, category } = getVariantCategoryFromUrl(
        courseRun.marketingUrl,
      );

      const conversionEvent: ConversionTracked = {
        correlation: { id: getCorrelationID() },
        metadata: {
          customName,
          action: "Started",
          category: "Enrollment",
          timestamp: new Date().toISOString(),
          productList: [
            {
              brand: getBrandString(course),
              category: category,
              variant: variant,
              name: courseRun.title || "unknown",
            },
          ],
        },
      };
      if (isUserEntitled) {
        conversionEvent.revenue = { actualRevenue: { amount: 0 } };
      }
      const event = makeEvent<ConversionTracked, EVENTS>(conversionEvent);
      event<ConversionTracked>(EVENTS.ConversionTracked)();
    };

  const enroll = async (
    type?: CourseEnrollButtonProps["conversionType"],
    opts?: { directToPayment?: boolean },
  ) => {
    if (!disabled) {
      setDisabled(true);
      try {
        await createEnrollment({
          courseId: courseRun.key,
          emailOptIn,
        });

        triggerConversionEvent(type)();
      } catch (error) {
        //TODO: There is specific error handling for 403, where the users get redirected to user_message_url in the error message
        //Otherwise normal redirect applies
      } finally {
        //Redirects to track in all cases
        window.location.href = opts?.directToPayment
          ? commerceCoodrinatorUrl
          : getTrackSelectionUrl(
              category as string,
              courseSlug as string,
              courseRun.key,
              useNewTrackSelect,
            );
      }
    }
  };

  const generateWebElement = (
    htmlId: string,
    text: string,
    componentId: string,
  ): ElementClicked["webElement"] => {
    const componentMap: Record<string, { name: string; location: string }> = {
      "sticky-nav": { name: "sticky-cta", location: "sticky-banner" },
      "ways-to-take-course": {
        name: "comparison-table-cta",
        location: "comparison-table",
      },
    };

    const { name, location } = componentMap[componentId] || {
      name: "hero-cta",
      location: "hero",
    };

    return {
      name,
      elementType: ElementType.Button,
      htmlId,
      location,
      text: hyphenateForTagular(text),
      position: htmlId === "audit-cta" ? "2" : "1",
    };
  };

  const handleEnrollment =
    (
      type: CourseEnrollButtonProps["conversionType"],
      htmlId: string,
      buttonText: string,
      opts = { directToPayment: false },
    ) =>
    async () => {
      const dynamicWebElement = generateWebElement(htmlId, buttonText, id);
      elementClicked({ webElement: dynamicWebElement });

      if (isEnterpriseUser) {
        openEnterpriseEnrollmentModal();
      } else {
        enrollButtonClickEvent(enrollmentClickEventPayload());
        await enroll(type, opts);
      }
    };

  const isStickyButtonTest = isSelfHiding && ctaLabel;

  const auditCourse = isCourseArchived ? null : (
    <button
      className={cn("underline self-center", !isStickyButtonTest && "mt-5")}
      onClick={handleEnrollment(
        "audit-cta",
        "audit-cta",
        auditCourseLabel ?? "Audit Course",
      )}
      disabled={disabled}
    >
      {auditCourseLabel}
    </button>
  );

  if (isLoading) {
    return (
      <div className="flex items-center">
        <Skeleton className="h-11 w-56 rounded-none" />
      </div>
    );
  }

  const notLoggedAndEnrollableCourseLink = linkDirectToPaymentPage
    ? commerceCoodrinatorUrl
    : `${AUTH_DOMAIN}/register?course_id=${encodeURIComponent(courseRun.key)}&enrollment_action=enroll&email_opt_in=${emailOptIn}`;

  const handleNotLoggedAndCourseEnrollableClick = () => {
    triggerConversionEvent(conversionType)();
    const dynamicWebElement = generateWebElement(
      "advance-free-cta",
      hyphenateForTagular(webElementText),
      id,
    );
    elementClicked({
      webElement: dynamicWebElement,
      outboundUrl: notLoggedAndEnrollableCourseLink,
    });
    window.location.href = notLoggedAndEnrollableCourseLink;
  };

  return (
    <div className={className}>
      {!isLoggedIn && isCourseEnrollable && (
        <div
          className={cn(
            "flex",
            isStickyButtonTest ? "flex-row gap-3 items-center" : "flex-col",
          )}
        >
          {isStickyButtonTest ? (
            <span className="font-bold">Start Today</span>
          ) : null}
          <button
            ref={loggedOutLinkRef}
            onClick={handleNotLoggedAndCourseEnrollableClick}
            className={cn(
              enrollButtonBaseStyles,
              buttonClass,
              isStickyButtonTest && " rounded-full px-6",
            )}
          >
            {ctaLabel ?? children ?? enrollButtonText}
          </button>
          {auditCourseLabel ? auditCourse : null}
        </div>
      )}
      {isLoggedIn && !isUserEnrolledInRun && isCourseEnrollable && (
        <div
          className={cn(
            "flex",
            isStickyButtonTest ? "flex-row gap-3 items-center" : "flex-col",
          )}
        >
          {isStickyButtonTest ? (
            <span className="font-bold">Start Today</span>
          ) : null}
          <button
            type="button"
            className={cn(
              enrollButtonBaseStyles,
              isUserEnrolledInCourse
                ? "bg-white border text-brand hover:bg-brand hover:text-white"
                : "",
              buttonClass,
              isStickyButtonTest && "rounded-full px-6",
            )}
            onClick={handleEnrollment(
              conversionType,
              "advance-free-cta",
              enrollButtonText,
              { directToPayment: Boolean(linkDirectToPaymentPage) },
            )}
            disabled={disabled}
            id={courseRun.key}
          >
            {ctaLabel ?? children ?? enrollButtonText}
          </button>

          {auditCourseLabel ? auditCourse : null}
        </div>
      )}
      {isLoggedIn && isUserEnrolledInRun && (
        <a
          href={
            courseStarted
              ? `${LMS_COURSE_DOMAIN}/course/${encodeURIComponent(courseRun.key)}/home`
              : `${LMS_DOMAIN}/dashboard`
          }
          className={cn(enrollButtonBaseStyles, buttonClass)}
        >
          {children || enrollButtonText}
        </a>
      )}
      <EnterpriseEnrollmentInformModal
        isOpen={isOpenEnterpriseModal}
        onContinue={enroll}
        closeModal={() => {
          setIsOpenEnterpriseModal(false);
        }}
      />
      {!isUserEnrolledInRun && !isCourseEnrollable && (
        <Alert className="p-6 rounded-xl bg-putty-100 text-primary drop-shadow-md text-center text-base">
          {children || enrollButtonText}
        </Alert>
      )}
    </div>
  );
}

export default CourseEnrollButton;
