import { LeadWithPaidTest, Result, UseLeadWithPaid } from "./types";
import { useMonarchRule } from "../MonarchRulesProvider";
import { FBE_EXEMPT_COURSES_MAP } from "@/lib/course/utils/constants";

const CUSTOM_RULE_ID = "leadWithPaidTest";

const useLeadWithPaid: UseLeadWithPaid = (course) => {
  const DEFAULT_CONVERSION_TYPE = "enroll";

  const isCourseExcluded = FBE_EXEMPT_COURSES_MAP.includes(course.productUuid);

  const { isLoading, value } = useMonarchRule<{
    data: LeadWithPaidTest;
    variantCustomId: string;
  }>(CUSTOM_RULE_ID, {
    courseModes: course?.activeCourseRun?.seats
      ?.map((seat) => seat?.type)
      .join(","),
  });

  const overview = value?.data?.overview?.reduce(
    (acc, curr) => {
      acc = acc ?? {};
      acc[curr.overviewItemPosition - 1] = {
        overviewHeader: curr?.overviewData?.overviewHeader,
        overviewLink: !isCourseExcluded
          ? curr?.overviewData?.overviewLink
          : undefined,
        overviewIcon: curr?.overviewData?.overviewIcon,
        overviewDescription: !isCourseExcluded
          ? curr?.overviewData?.overviewDescription
          : "Advance your career today!",
      };

      return acc;
    },
    {} as Result["overview"],
  );

  return {
    isLoading,
    value: {
      ...value?.data,
      overview,
      conversionType:
        value?.data?.eventing?.conversionType ?? DEFAULT_CONVERSION_TYPE,
    },
  };
};

export default useLeadWithPaid;
