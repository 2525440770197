import { Enrollment } from "@/lib/partner-api/getEnrollments";

const isUserEnrolled = (
  userEnrollments: Enrollment[],
  courseId: string | string[],
) => {
  const isEnrolled =
    userEnrollments?.some(({ courseDetails }) =>
      courseId.includes(courseDetails.courseId),
    ) || false;
  return isEnrolled;
};

export default isUserEnrolled;
