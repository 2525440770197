import React from "react";
import CTALink from "@/components/CTA/ctaLink";
import isUserLoggedIn from "@/lib/auth/isUserLoggedIn";
import { PAID_MODES } from "@/lib/course/utils/constants";
import { Seat } from "@/lib/courseRun/types";
import { hyphenateForTagular } from "@/utils/tagular/helpers";

type Props = {
  courseName: string;
  seats: Seat[];
  isArchived: boolean;
};

const getBusinessSeatUrl = (seats: Seat[], isArchived: boolean) => {
  if (isArchived)
    return "https://business.edx.org/?lead_source=edX+Course+Page&utm_campaign=B2B+Container&utm_source=Referral";
  const businessSeat = seats.find(({ type }) => PAID_MODES.has(type));

  return businessSeat && businessSeat.bulkSku
    ? `https://ecommerce.edx.org/basket/add/?sku=${businessSeat.bulkSku}`
    : "";
};

function EnterpriseCTA({ seats, isArchived, courseName }: Props) {
  const businessSeatUrl = getBusinessSeatUrl(seats, isArchived);
  const isLoggedIn = isUserLoggedIn();

  return (
    <div className="bg-putty-300 py-14 px-4 sm:px-[200px] items-center flex flex-col gap-8">
      <div className="flex flex-col gap-4 text-center">
        <h2 className="font-inter font-black italic text-4xl sm:text-6xl text-primary-500 mb-0 mt-0">
          Interested in this course for your business or team?
        </h2>

        <p className="font-inter text-base text-gray-800 mb-0">
          Train your employees in the most in-demand topics, with edX for
          business
        </p>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
        {businessSeatUrl && isLoggedIn ? (
          <CTALink
            label="Purchase now"
            url={businessSeatUrl}
            size="lg"
            location="enterprise-solutions-asset"
            html_id="purchase-now-CTA"
            name={hyphenateForTagular(courseName)}
            type="brand"
            className="py-2.5 px-6 w-full sm:w-auto font-normal"
          />
        ) : null}
        <CTALink
          label="Request more info"
          url="https://business.edx.org/?lead_source=edX+Course+Page&utm_campaign=B2B+Container&utm_source=Referral"
          size="lg"
          location="enterprise-solutions-asset"
          html_id="request-info-CTA"
          name={hyphenateForTagular(courseName)}
          type={businessSeatUrl && isLoggedIn ? "brandOutline" : "brand"}
          className="py-2.5 px-6 w-full sm:w-auto font-normal"
        />
      </div>
    </div>
  );
}

export default EnterpriseCTA;
