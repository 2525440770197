import CetificateTabs from "./components/CertificateTabs";
import getCoursePrice from "@/lib/course/utils/getCoursePrice";
import { ProcessedCourse } from "@/lib/course/types";
import { CourseRun } from "@/lib/courseRun/types";
import CertificateInfoTab from "./components/CertificateInfoTab";
import CertificateTestimonialTab from "./components/CertificateTestimonialTab";
import CertificateStatsTab from "./components/CertificateStatsTab";
import PathInfoTab from "./components/PathInfoTab";
import {
  CertificateEmphasisInformation,
  CertificateEmphasisStats,
  CertificateEmphasisTestimonial,
} from "@/monarch/rules/certificateEmphasisRedesign/types";
import { PathInfo } from "@/components/CourseContent/ContentRefresh/Certificate/CertificateEmphasisTabs/components/PathInfoTab/types";

interface CertificateEmphasisTabsProps {
  course: ProcessedCourse;
  courseRun: CourseRun;
  information?: CertificateEmphasisInformation;
  stats?: CertificateEmphasisStats;
  testimonial?: CertificateEmphasisTestimonial;
  tabOrder?: string[];
}

export default function CertificateEmphasisTabs({
  course,
  courseRun,
  information,
  stats,
  testimonial,
  tabOrder: externalTabOrder,
}: CertificateEmphasisTabsProps) {
  const coursePrice = getCoursePrice(courseRun);

  const availableTabs = {
    about: {
      tabHeading: "About this certificate",
      content: <CertificateInfoTab externalData={information} />,
      id: "about",
    },
    testimonials: {
      tabHeading: "What others are saying",
      content: <CertificateTestimonialTab externalData={testimonial} />,
      id: "testimonials",
    },
    stats: {
      tabHeading: "Advance your career",
      content: <CertificateStatsTab externalData={stats} />,
      id: "stats",
    },
    paths: {
      tabHeading: "Ways to take course",
      content: <PathInfoTab coursePrice={coursePrice} />,
      id: "paths",
    },
  };

  const defaultTabOrder = ["about", "testimonials", "stats", "paths"];

  const processedTabOrder = (() => {
    if (
      !externalTabOrder ||
      !Array.isArray(externalTabOrder) ||
      externalTabOrder.length === 0
    ) {
      return defaultTabOrder;
    }

    const validTabKeys = Object.keys(availableTabs);

    const uniqueTabOrder: string[] = [];
    const seenTabs = new Set<string>();

    externalTabOrder.forEach((key) => {
      if (
        typeof key === "string" &&
        validTabKeys.includes(key) &&
        !seenTabs.has(key)
      ) {
        uniqueTabOrder.push(key);
        seenTabs.add(key);
      }
    });

    if (uniqueTabOrder.length === 0) {
      return defaultTabOrder;
    }

    const missingTabs = defaultTabOrder.filter(
      (tabKey) => !uniqueTabOrder.includes(tabKey),
    );

    return [...uniqueTabOrder, ...missingTabs];
  })();

  const orderedTabs = processedTabOrder.map(
    (tabKey) => availableTabs[tabKey as keyof typeof availableTabs],
  );

  return (
    <CetificateTabs tabs={orderedTabs} course={course} courseRun={courseRun} />
  );
}
