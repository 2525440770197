import { CTAProps } from "../types";

export default function CopyRightPlusButton({
  title,
  subtitle,
  titleSize = "small",
  children,
  border = "top",
}: CTAProps) {
  const borderClasses = {
    top: "border-t-[0.625rem]",
    bottom: "border-b-[0.625rem]",
    both: "border-t-[0.625rem] border-b-[0.625rem]",
    full: "border-[0.625rem]",
  };

  const titleSizeVariants = {
    small: "text-[1.125rem]",
    default: "text-m lg:text-l",
    large: "text-2l lg:text-xl",
  };

  return (
    <div className={`bg-[#fbfaf9] border-light ${borderClasses[border]}`}>
      <div className="bg-primary h-1"></div>
      <div className="flex flex-wrap pt-[1.875rem] pb-[0.9375rem] px-[1.875rem]">
        <div className="flex flex-col sm:pr-8 justify-center grow mb-5 sm:mb-0">
          <h3
            className={`uppercase ${titleSizeVariants[titleSize]} text-gray-dark m-0 leading-[1.5rem]`}
          >
            {title}
          </h3>
          <p className="text-sm leading-[1.25rem] mb-0">{subtitle}</p>
        </div>
        <div className="flex flex-col text-center grow-0 shrink-0 basis-[11.375rem] ml-auto">
          {children}
        </div>
      </div>
    </div>
  );
}
