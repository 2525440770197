"use client";
import React, { useEffect } from "react";
import { getEnrollButtonText } from "@/lib/course/utils/getCourseRunEnrollButtonText";
import { isDateInPast } from "@/lib/date";
import { enrollButtonClickEvent } from "@/lib/segment";
import { Skeleton } from "@/components/ui/skeleton";
import {
  CourseEnrollButtonClickEvents,
  CourseEnrollProps,
} from "../../EnrollButton/types";
import EnrollLink from "./CourseEnrollButtons/EnrollLink";
import AuditLink from "./CourseEnrollButtons/AuditLink";
import { cn } from "@/lib/utils";
import { getCourseRunStartText } from "@/lib/course/utils/getCourseRunTilePrimaryText";
import { getCourseRunEndText } from "@/lib/course/utils/getCourseRunTitleSecondaryText";
import useCourseEnrollment from "./hooks/useCourseEnrollment";

function CourseEnroll({
  id,
  buttonClass = "",
  className = "",
  courseRun,
  course,
  showEndDate = true,
  showStartDate = true,
  isStickyCta = false,
  showAuditLink = true
}: CourseEnrollProps) {
  const {
    isUserEnrolledInRun,
    isCourseEnrollable,
    isPaidLeadLoading,
    isCourseArchived,
    getEnrollmentClickEventData,
  } = useCourseEnrollment({
    courseRun,
    course,
  });
  const loggedOutLinkRef = React.useRef<HTMLAnchorElement | null>(null);
  const hasClickEvents = React.useRef<CourseEnrollButtonClickEvents>({});

  useEffect(() => {
    if (hasClickEvents.current[id]) {
      return;
    }

    if (loggedOutLinkRef?.current) {
      enrollButtonClickEvent({
        domElement: loggedOutLinkRef.current,
        ...getEnrollmentClickEventData(),
      });
      hasClickEvents.current[id] = true;
    }
  }, [loggedOutLinkRef.current, id]);

  const enrollButtonText = getEnrollButtonText(
    {
      enrollmentStart: courseRun.enrollmentStart,
      enrollmentEnd: courseRun.enrollmentEnd,
      end: courseRun.end,
    },
    isUserEnrolledInRun || false,
  );

  if (isPaidLeadLoading) {
    return (
      <div className="flex flex-row gap-3 items-center bg-putty-100 p-4 rounded-xl border-putty-400 border">
        <Skeleton role="status" className="h-11 w-56 rounded-none" />
      </div>
    );
  }
  //
  const enrollmentEnded = courseRun.enrollmentEnd
    ? isDateInPast(courseRun.enrollmentEnd)
    : false;

  const auditLinkClassName = isStickyCta
    ? "max-md:bg-transparent max-md:border-none max-md:text-white max-md:underline max-md:p-0"
    : "";
  const fontColor = isStickyCta ? "text-white" : "";

  return (
    <>
      <div
        className={cn(
          "flex flex-col md:flex-row gap-4 sm:gap-2 items-center justify-between bg-putty-100 p-4 rounded-xl border-putty-400 border w-auto xl:w-[calc(50%-12px)] [&:only-child]:w-auto",
          className || "",
        )}
      >
        {isCourseArchived || !isCourseEnrollable ? (
          <span className={`font-bold text-base ${fontColor}`}>
            This course is archived
          </span>
        ) : (
          <div className={`text-center md:text-left ${fontColor}`}>
            <div className={`${showStartDate ? "block" : "hidden md:block"} font-bold ${!isStickyCta ? "md:mb-1" : ""}`}>
              {getCourseRunStartText(courseRun)}
            </div>
            {showEndDate ? (
              <div className="md:mt-1">{getCourseRunEndText(courseRun)}</div>
            ) : null}
          </div>
        )}

        {isCourseEnrollable ? (
          <div
            className={`flex flex-col md:flex-row gap-2 sm:gap-3 items-center ${isStickyCta ? "w-full md:w-auto md:pl-3" : ""}`}
          >
            <EnrollLink
              buttonClass={buttonClass}
              courseRun={courseRun}
              course={course}
            />
              <div className={`${showAuditLink ? "block" : "hidden md:block"
                } `}>
                <AuditLink
                  courseRun={courseRun}
                  course={course}
                  className={auditLinkClassName}
                />
              </div>
          </div>
        ) : !isUserEnrolledInRun && enrollmentEnded ? (
          <span className={fontColor}>Future dates to be announced</span>
        ) : null}
      </div>
    </>
  );
}

export default CourseEnroll;
