import {
  CertificateEmphasisRedesignProps,
  UseCertificateEmphasisRedesign,
} from "./types";
import { useMonarchRule } from "../MonarchRulesProvider";

const CUSTOM_RULE_ID = "courseCertificateEmphasisTest";

const useCertificateEmphasisRedesign: UseCertificateEmphasisRedesign = () => {
  const { value } = useMonarchRule<{
    data: CertificateEmphasisRedesignProps;
    variantCustomId: string;
  }>(CUSTOM_RULE_ID, {});

  return {
    showRedesignedTrackComparison: value?.data?.showRedesignedTrackComparison,
    emphasisModuleVersion: value?.data?.emphasisModuleVersion,
    information: value?.data?.information,
    stats: value?.data?.stats,
    testimonial: value?.data?.testimonial,
    tableDescription: value?.data?.tableDescription,
    pathInfo: value?.data?.pathInfo,
    tabsOrder: value?.data?.tabsOrder,
    hidePrice: value?.data?.hidePrice,
  };
};

export default useCertificateEmphasisRedesign;
