import parseToText from "./parseToText";

type Faqs = {
  question: string;
  answer: string;
};
export const parseFAQs = (faqsHtml: string | null) => {
  if (!faqsHtml) return [];
  const faqs: Faqs[] = [];

  if (faqsHtml.indexOf("?") === -1) return faqsHtml;

  const hasLinks = faqsHtml.match(/<a\s+href\s*=[^>]*>/i);
  if (hasLinks) return faqsHtml;

  const faqMatches = faqsHtml.match(/<p>[\s\S]*?<\/p>/g);
  if (!faqMatches) return faqsHtml;

  let indexFaqs = -1;
  faqMatches.forEach((faq) => {
    const textContent = parseToText(faq);
    const questionEndIndex = textContent.indexOf("?");

    if (questionEndIndex !== -1) {
      const question = textContent.substring(0, questionEndIndex + 1).trim();
      const answer = textContent.substring(questionEndIndex + 1).trim();

      if (question && answer) {
        faqs.push({ question, answer });
        indexFaqs++;
      }
    } else {
      if (indexFaqs === -1) return faqsHtml;

      faqs[indexFaqs].answer += " " + textContent;
    }
  });
  return faqs;
};
