"use client";
import { EnterpriseEnrollmentInformModalProps } from "../../types";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Dialog";

function EnterpriseEnrollmentInformModal({
  isOpen = false,
  closeModal,
  onContinue,
}: EnterpriseEnrollmentInformModalProps) {
  const handleContinueSubmit = () => {
    onContinue();
    closeModal();
  };
  return (
    <Dialog open={isOpen} onOpenChange={closeModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Before you can enroll</DialogTitle>
        </DialogHeader>
        <p>
          {`It looks like you are currently logged in to an account with access to
          an Enterprise profile. In order to use your organization's learning
          program, you will need to enroll in this course using your Enterprise
          profile.`}
        </p>
        <DialogFooter>
          <DialogClose
            onClick={closeModal}
            className="md:mr-2 border border-primary p-3 mb-2 md:mb-0"
          >
            Cancel
          </DialogClose>
          <button
            className="bg-primary text-white hover:bg-primary-light p-3 mb-2 md:mb-0"
            onClick={handleContinueSubmit}
          >
            Continue
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default EnterpriseEnrollmentInformModal;
