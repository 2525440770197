import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from "@/components/Dialog";

const EnterpriseNotificationModal = ({
  isOpen = false,
  onContinue,
  closeModal,
}: {
  isOpen: boolean;
  onContinue: () => void;
  closeModal: () => void;
}) => {
  const handleContinueSubmit = () => {
    onContinue();
    closeModal();
  };

  return (
    <Dialog open={isOpen} onOpenChange={closeModal}>
      <DialogContent className="p-7 gap-0 !bg-putty-200">
        <DialogHeader className="pb-10">
          <DialogTitle className="text-2xl italic">
            Before you can enroll
          </DialogTitle>
        </DialogHeader>
        <hr />
        <p className="m-0 text-sm pb-5">
          It looks like you are currently logged in to an account with access to
          an Enterprise profile. In order to use your organization’s learning
          program, you will need to enroll in this course using your Enterprise
          profile.
        </p>
        <DialogFooter className="flex flex-col md:flex-row-reverse lg:flex-row-reverse xl:flex-row-reverse h-14 sm:h-10 gap-4 p-0 w-full">
          <DialogClose
            className="bg-white text-sm text-brand shadow border-2 border-brand hover:bg-brand hover:text-white !h-auto px-4 rounded-md"
            onClick={closeModal}
          >
            <>Cancel </>
          </DialogClose>
          <button
            className="text-white bg-brand text-sm md:w-auto hover:bg-white 
              hover:text-brand border-2 border-brand px-4 rounded-md"
            onClick={handleContinueSubmit}
          >
            Continue
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EnterpriseNotificationModal;
