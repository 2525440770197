import React from "react";
import parse from "html-react-parser";
import styles from "./index.module.css";
import { PartialPartner } from "@/lib/partner/types";
import { PartialProgram, ProcessedCourseSubject } from "@/lib/course/types";
import { useLocale } from "next-intl";
import getTopicUrl from "@/lib/course/utils/getTopicsUrl";
import Link from "next/link";

type Props = {
  institutions: PartialPartner[] | null;
  subjects: ProcessedCourseSubject[] | null;
  level: string | null;
  prerequisites: string | null;
  language: string | null;
  videoTranscript: string | null;
  associatedPrograms: PartialProgram[] | null;
  associatedSkills: string | null;
};

export default function AtAGlance({
  institutions,
  subjects,
  level,
  prerequisites,
  language,
  videoTranscript,
  associatedPrograms,
  associatedSkills,
}: Props) {
  const skillCharactersBreakPoint = 200;
  const locale = useLocale();
  const primarySubject =
    subjects &&
    subjects.find(
      (subject: ProcessedCourseSubject) => subject.languageCode === locale,
    );

  const programsJSX = (
    <li className={styles.parsed}>
      <span className="font-bold mr-1">Associated programs:</span>
      {associatedPrograms &&
        associatedPrograms.map((program: PartialProgram, index: number) => (
          <React.Fragment key={program.title}>
            {index === 0 && <br />}
            <span>{program.type} in </span>
            <a
              href={`/${program.marketingUrl}`}
              className="pointer text-black-100 underline"
            >
              {program.title}
            </a>
            {index < associatedPrograms.length - 1 && <br />}
          </React.Fragment>
        ))}
    </li>
  );
  const contentLanguageJSX = language && (
    <AtAGlanceItem label="Language" value={language} />
  );
  const videoTranscriptsJSX = videoTranscript && (
    <AtAGlanceItem label="Video Transcript" value={videoTranscript} />
  );

  return (
    <div className="pt-6 sm:pt-4 sm:mt-4 text-base">
      <h3 className="pb-2 mb-0 sm:mb-2 mt-0 text-lg text-black">At a glance</h3>
      <div className="bg-block text-primary py-4 px-2 flex flex-wrap">
        <div className="grow-0 shrink-0 sm:basis-1/2 max-w-full sm:max-w-[50%] px-4">
          <ul className="block text-black list-disc !ps-4">
            {institutions && (
              <li className={styles.parsed}>
                <span className="font-bold mr-1">
                  {institutions.length > 1 ? "Institutions" : "Institution"}:
                </span>
                {institutions
                  .sort((a, b) => (a.sortValue ?? 0) - (b.sortValue ?? 0))
                  .map((institution: PartialPartner, index: number) => {
                    return institution.showOnSchoolsAndPartnersPage ? (
                      <Link
                        key={institution.key}
                        href={`/school/${institution.slug}`}
                        className="pointer text-black-100 underline"
                      >
                        {institution.key}
                        {index < institutions.length - 1 && ", "}
                      </Link>
                    ) : (
                      <span>
                        {institution.key}
                        {index < institutions.length - 1 && ", "}
                      </span>
                    );
                  })}
              </li>
            )}
            {primarySubject && (
              <li className={styles.parsed}>
                <span className="font-bold mr-1">Subject:</span>
                <a
                  key={primarySubject.name}
                  href={getTopicUrl(locale, primarySubject.slug)}
                  className={"pointer text-black-100 underline"}
                >
                  {primarySubject.name}
                </a>
              </li>
            )}
            <AtAGlanceItem label="Level" value={level} />
            <AtAGlanceItem label="Prerequisites" value={prerequisites} />
            {(associatedPrograms?.length || 0) &&
            (associatedSkills?.length || 0) > skillCharactersBreakPoint
              ? programsJSX
              : null}
            {(associatedPrograms?.length || 0) >= 3 && language
              ? contentLanguageJSX
              : null}
            {videoTranscript && (associatedPrograms?.length || 0) >= 3
              ? videoTranscriptsJSX
              : null}
          </ul>
        </div>
        <div className="grow-0 shrink-0 sm:basis-1/2 max-w-full sm:max-w-[50%] px-4">
          <ul className="block text-black list-disc !ps-4">
            {(associatedPrograms?.length || 0) < 3 && language
              ? contentLanguageJSX
              : null}
            {videoTranscript && (associatedPrograms?.length || 0) < 3
              ? videoTranscriptsJSX
              : null}
            {(associatedPrograms?.length || 0) &&
            (associatedSkills?.length || 0) <= skillCharactersBreakPoint
              ? programsJSX
              : null}
            <AtAGlanceItem label="Associated skills" value={associatedSkills} />
          </ul>
        </div>
      </div>
    </div>
  );
}

type AtAGlanceItemProps = {
  label: string;
  value?: string | null;
};

const AtAGlanceItem = ({ label, value }: AtAGlanceItemProps) => {
  if (!value) {
    return null;
  }
  return (
    <li className={styles.parsed}>
      <span className="font-bold mr-1">{label}:</span>
      <span
        className={
          value === "MISSING FROM DATA" ? "text-red-600 font-bold" : ""
        }
      >
        {parse(value)}
      </span>
    </li>
  );
};
