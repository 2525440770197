"use client";
import { useCourseContext } from "..";

const useEmailOptIn = () => {
  const { dispatch, state } = useCourseContext();

  const setEmailOptIn = (emailOptIn: boolean) => {
    dispatch({ type: "SET_EMAIL_OPT_IN", payload: emailOptIn });
  };

  return {
    setEmailOptIn,
    emailOptIn: state.emailOptIn,
  };
};

export default useEmailOptIn;
