import { CourseRun } from "@/lib/courseRun/types";
import { COURSE_PACING_INSTRUCTOR, COURSE_PACING_SELF } from "./constants";
import {
  getI18nDateContextualString,
  getI18nDateString,
  getI18nDayMonthString,
  isDateInCurrentYear,
  isEndingWithinThisYear,
} from "@/lib/date";

const getCourseRunTileSecondaryText = ({
  isEnrollable,
  end,
  pacingType,
}: CourseRun) => {
  if (!end) {
    return null;
  }
  const courseRunEndDate = new Date(end);
  if (isEnrollable && courseRunEndDate > new Date()) {
    if (pacingType === COURSE_PACING_INSTRUCTOR) {
      const endDate = isEndingWithinThisYear(courseRunEndDate)
        ? // instructor_paced courses with end dates within the next calendar year drop the year
          getI18nDayMonthString(courseRunEndDate)
        : getI18nDateString(courseRunEndDate);
      return `Ends ${endDate}`;
    }
    if (pacingType === COURSE_PACING_SELF) {
      // self-paced courses with an end-date less than a year away returns day, month; null otherwise
      // this is to keep consistent with current logic for the experiment
      if (isEndingWithinThisYear(courseRunEndDate)) {
        return `Ends ${getI18nDayMonthString(courseRunEndDate)}`;
      }
    }
  }
  return null;
};

export const getCourseRunEndText = ({
  isEnrollable,
  end,
  pacingType,
}: CourseRun) => {
  if (!end) {
    return null;
  }
  const courseRunEndDate = new Date(end);
  if (isEnrollable && courseRunEndDate > new Date()) {
    if (pacingType === COURSE_PACING_INSTRUCTOR) {
      const endDate = getI18nDateContextualString(courseRunEndDate);
      return `Ends ${endDate}`;
    }
    if (pacingType === COURSE_PACING_SELF) {
      // self-paced courses with an end-date less than a year away returns day, month; null otherwise
      // this is to keep consistent with current logic for the experiment
      if (isEndingWithinThisYear(courseRunEndDate)) {
        return `Ends ${getI18nDateContextualString(courseRunEndDate)}`;
      }
    }
  }
  return null;
};

export default getCourseRunTileSecondaryText;
