import { PacingType } from "@/lib/program/types";
import { EnrollmentButtonClickEventData, numberOr } from "@/lib/segment";
import { getCourseSubject } from "@/utils/tagular/helpers";
import { EnrollClickEventProps } from "../types";

const getEnrollmentClickEventPayload = ({
  courseRun,
  emailOptIn,
  course,
  shouldHaveQueryParams,
  isUserEnrolledInRun,
  isLoggedIn,
  isUserEntitled,
  isMobile,
  searchParams,
}: EnrollClickEventProps): EnrollmentButtonClickEventData => ({
  // Event attributes for all scenarios
  label: courseRun?.key,
  userEnrolled: Boolean(isUserEnrolledInRun),
  userLoggedIn: isLoggedIn,
  userEntitled: isUserEntitled,
  link: !isLoggedIn ? courseRun?.enrollmentUrl : {},
  emailOptIn: emailOptIn,
  category: "navigation",
  objectId: `course-${courseRun?.uuid}`,

  // Event attributes for all referrers and logged in
  ...(isLoggedIn && {
    component: "enroll-button-link",
    subject: getCourseSubject(course),
    url: window?.location?.href,
    mobile: isMobile.toString(),
    activeCourseRunKey: courseRun?.key,
    courseUuid: courseRun?.uuid,
    isSelfPaced: courseRun?.pacingType === PacingType.SELFPACED,
  }),

  // Event attributes for from Search Page queried and Course Page
  ...(shouldHaveQueryParams && {
    index: searchParams.get("index") ?? "product",
    queryId: searchParams.get("queryId") ?? undefined,
    position: numberOr(searchParams.get("position"), 0),
  }),
});

export default getEnrollmentClickEventPayload;
