import React from "react";
import CertificateExampleImg from "@/public/bg-images/example-certificate-verified.webp";
import Image from "next/image";

interface CertificateImageProps {
  width?: number;
  height?: number;
}

export default function CertificateImage({
  width = 500,
  height = 342,
}: CertificateImageProps) {
  return (
    <div className="flex items-center justify-center md:min-w-[400px] xl:shrink-0">
      <Image
        src={CertificateExampleImg}
        alt="Certificate Example"
        width={width}
        height={height}
      />
    </div>
  );
}
