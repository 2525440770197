"use client";
import { Enrollment } from "@/lib/partner-api/getEnrollments";
import { useCourseContext } from "..";

const useUserEnrollments = () => {
  const { dispatch, state } = useCourseContext();

  const setUserEnrollments = (enrollments: Enrollment[]) => {
    dispatch({ type: "SET_USER_ENROLLMENTS", payload: enrollments });
  };

  const setIsUserEnrolled = (isUserEnrolled: boolean) => {
    dispatch({ type: "SET_IS_USER_ENROLLED", payload: isUserEnrolled });
  };

  return {
    setUserEnrollments,
    setIsUserEnrolled,
    userEnrollments: state.userEnrollments,
    isUserEnrolled: state.isUserEnrolled,
  };
};

export default useUserEnrollments;
