"use client";
import { useState, useEffect, useRef } from "react";

import { useRouter } from "next/navigation";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import CourseEnrollButton from "../EnrollButton";
import getCourseRunTilePrimaryText from "@/lib/course/utils/getCourseRunTilePrimaryText";
import { CourseRun } from "@/lib/courseRun/types";
import { ProcessedCourse } from "@/lib/course/types";
import { CourseEnrollButtonProps } from "../EnrollButton/types";

type Props = {
  links: { label: string; path: string }[];
  courseRun: CourseRun | null;
  course: ProcessedCourse;
  requestMoreInfoButton?: { label: string; path: string } | null;
};
export default function StickyNav({
  links,
  courseRun,
  course,
  requestMoreInfoButton,
}: Props) {
  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);
  const [isNavOnTop, setIsNavOnTop] = useState(false);

  useEffect(() => {
    if (window.scrollY === ref.current?.offsetTop) {
      setIsNavOnTop(true);
    }
    const onScroll = () => {
      if (ref.current?.offsetTop) {
        const isTop = Math.abs(window.scrollY - ref.current?.offsetTop) < 87;
        setIsNavOnTop(isTop);
      }
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div
      className="hidden md:block sticky top-0 w-screen ml-[calc(-50vw+50%)] z-50 shadow-xl"
      ref={ref}
    >
      {isNavOnTop && courseRun?.title && (
        <div className={"bg-primary px-5"}>
          <h2 className="text-primary-foreground mx-auto py-2 text-sm font-bold leading-5 m-0">
            {courseRun.title}
          </h2>
        </div>
      )}
      <div className="bg-[#eae6e5] px-5 flex">
        <div className="px-4 mx-auto flex items-center gap-4 justify-between max-w-7xl w-full">
          <nav className="hidden lg:flex py-4 mx-1 flex-wrap gap-10 text-base">
            {links.map((link) => (
              <a href={link.path} key={`lg-${link.path}`}>
                {link.label}
              </a>
            ))}
          </nav>
          <div className="lg:hidden grow py-4 flex items-center justify-center">
            <Select onValueChange={(e) => router.push(e)}>
              <SelectTrigger className="bg-white text-base">
                <SelectValue placeholder="About" />
              </SelectTrigger>
              <SelectContent>
                {links.map((link) => (
                  <SelectItem
                    key={`md-${link.path}`}
                    value={link.path}
                    className="text-base"
                  >
                    {link.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {isNavOnTop && courseRun && (
            <CourseEnrollButton
              id="sticky-nav"
              isSelfHiding
              courseRun={courseRun}
              course={course}
            >
              <div className="min-w-32 text-center leading-4">
                <h4 className="my-0 text-base text-white">Enroll now</h4>
                <span className="text-xs text-white">
                  {getCourseRunTilePrimaryText(courseRun)}
                </span>
              </div>
            </CourseEnrollButton>
          )}
          {isNavOnTop && requestMoreInfoButton && (
            <a
              href={requestMoreInfoButton.path}
              key={`lg-${requestMoreInfoButton.path}`}
              className="inline-block bg-brand text-base text-white text-center content-center items-center min-w-full md:min-w-32 py-2 px-4 hover:bg-brand-dark justify-center"
            >
              {requestMoreInfoButton.label}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
