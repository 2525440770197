"use client";
import React, { useState } from "react";
import { CourseAuditLinkProps } from "@/components/CourseContent/EnrollButton/types";
import useCourseEnrollment from "../hooks/useCourseEnrollment";
import { cn } from "@/lib/utils";
import { enrollButtonBaseStyles } from "./constants";
import { ElementType } from "@/constants/tagular/main";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import useElementEvents from "@/hooks/eventing/useElementEvents";

const baseStyles = `${enrollButtonBaseStyles} bg-white text-brand border-2 border-brand hover:bg-brand hover:text-white`;

function AuditLink({
  className = "",
  courseRun,
  course,
  location = "",
}: CourseAuditLinkProps) {
  const [isDisabled, setDisabled] = useState(false);
  const {
    isLoggedIn,
    isUserEnrolledInRun,
    isCourseEnrollable,
    handleEnrollment,
    paidLeadValues,
    isCourseArchived,
  } = useCourseEnrollment({
    courseRun,
    course,
  });
  //TODO: Replace with real API DATA
  const isEnterpriseUser = false;

  const { auditCourseLabel } = paidLeadValues || {};

  const webElement: ElementClicked["webElement"] = {
    name: "course-conversion",
    elementType: ElementType.Button,
    htmlId: "audit",
    location: location,
    text: "Audit course",
    position: "2",
  };

  const { elementClicked } = useElementEvents({
    webElement,
  });

  const onEnroll = () => {
    if (!isDisabled) {
      setDisabled(true);
      location === "cert-module"
        ? elementClicked({ webElement: webElement })
        : null;
      if (isEnterpriseUser) {
        //TODO: OPEN ENTERPRISE MODEL
      } else {
        handleEnrollment("audit-cta");
      }
    }
  };

  const auditCourse = isCourseArchived ? null : (
    <button
      className={cn(baseStyles, className)}
      onClick={onEnroll}
      disabled={isDisabled}
    >
      {auditCourseLabel}
    </button>
  );

  return (
    <>
      {!isLoggedIn &&
        isCourseEnrollable &&
        (auditCourseLabel ? auditCourse : null)}
      {isLoggedIn &&
        !isUserEnrolledInRun &&
        isCourseEnrollable &&
        (auditCourseLabel ? auditCourse : null)}
    </>
  );
}

export default AuditLink;
