import { CourseRun } from "@/lib/courseRun/types";
import getCourseRunStartDate from "./getCourseRunStartDate";
import {
  getI18nDateString,
  getI18nDayMonthString,
  isEndingWithinThisYear,
} from "@/lib/date";

const daysPerWeek = 7;

const getCourseRunExpirationDateString = (activeCourseRun: CourseRun) => {
  const startDate = getCourseRunStartDate(activeCourseRun);
  const estimatedDuration = activeCourseRun.weeksToComplete;

  if (!startDate || !estimatedDuration) {
    return null;
  }

  const expirationDate = new Date(startDate);
  expirationDate.setDate(
    expirationDate.getDate() + estimatedDuration * daysPerWeek,
  );

  if (isEndingWithinThisYear(expirationDate)) {
    return getI18nDayMonthString(expirationDate);
  }

  return getI18nDateString(expirationDate);
};

export default getCourseRunExpirationDateString;
