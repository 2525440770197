import CTALink from "@/components/CTA/ctaLink";
import { PAID_MODES } from "@/lib/course/utils/constants";
import { Seat } from "@/lib/courseRun/types";
import React from "react";
import { hyphenateForTagular } from "@/utils/tagular/helpers";

type Props = {
  courseName: string;
  seats: Seat[];
  isArchived: boolean;
};

const getBusinessSeatUrl = (seats: Seat[], isArchived: boolean) => {
  if (isArchived)
    return "https://business.edx.org/?lead_source=edX+Course+Page&utm_campaign=B2B+Container&utm_source=Referral";
  const businessSeat = seats.find(({ type }) => PAID_MODES.has(type));

  return businessSeat && businessSeat.bulkSku
    ? `https://ecommerce.edx.org/basket/add/?sku=${businessSeat.bulkSku}`
    : "";
};

function BusinessBlock({ seats, isArchived, courseName }: Props) {
  const businessSeatUrl = getBusinessSeatUrl(seats, isArchived);
  return (
    <div className="container md:pb-8 lg:py-0">
      <div className="flex flex-wrap">
        <div className="w-full lg:w-3/12 relative">
          <div className="relative lg:py-[50px] lg:w-[300px]">
            <h2 className="text-primary-500 md:mb-4 lg:mr-4 text-[2.25rem] lg:text-2xl leading-[2.5rem] hidden md:block">
              Interested in this course for your business or team?
            </h2>
            <span className="lg:absolute lg:top-0 lg:right-[-23px] lg:bottom-0 lg:w-[500%] bg-putty-200 transform -skew-x-6 z-[-1] hidden lg:block"></span>
          </div>
        </div>
        <div className="w-full lg:w-9/12 flex flex-col justify-center text-primary-500">
          <div className="text-base lg:ml-[114px]">
            Train your employees in the most in-demand topics, with edX For
            Business.
            <div className="flex flex-col sm:flex-row mt-4 w-full space-y-2 sm:space-y-0 sm:space-x-2">
              {businessSeatUrl ? (
                <CTALink
                  label="Purchase now"
                  url={businessSeatUrl}
                  radius="default"
                  className="bg-primary border border-primary hover:bg-[#2d494e] text-white hover:text-white mr-2 w-full sm:w-auto rounded-full"
                  size="lg"
                  location="enterprise-solutions-asset"
                  html_id="purchase-now-CTA"
                  name={hyphenateForTagular(courseName)}
                />
              ) : null}
              <CTALink
                label="Request information"
                url="https://business.edx.org/?lead_source=edX+Course+Page&utm_campaign=B2B+Container&utm_source=Referral"
                radius="default"
                className="bg-white border border-[#f2f0ef] text-primary hover:text-white hover:bg-primary shadow-none w-full sm:w-auto rounded-full"
                size="lg"
                location="enterprise-solutions-asset"
                html_id="request-info-CTA"
                name={hyphenateForTagular(courseName)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessBlock;
