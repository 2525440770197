import AccordionText from "@/components/AccordionTextRefresh";
import AccordionTextItem from "@/components/AccordionTextRefresh/AccordionTextItemRefresh";
import { staticOfacRestriction } from "@/components/ProgramDetailLayout/constants";
import { ProcessedCourse } from "@/lib/course/types";
import { parseFAQs } from "@/utils/functions/parseFAQs";
import parse from "html-react-parser";

export interface Props {
  course?: ProcessedCourse;
}
const OFACText: string = `
<p><strong>Who can take this course?</strong></p>
<p> Unfortunately, learners residing in one or more of the following countries or regions will not be able to register for this course:
    Iran, Cuba and the Crimea region of Ukraine. While edX has sought licenses from the U.S. Office of Foreign Assets Control (OFAC) to
    offer our courses to learners in these countries and regions, the licenses we have received are not broad enough to allow us to offer
    this course in all locations. edX truly regrets that U.S. sanctions prevent us from offering all of our courses to everyone, no matter where they live. </p>`;

export default function FAQs({ course }: Props) {
  const { frequentlyAskedQuestions, hasOfacRestrictions } = course ?? {};
  if (!course?.frequentlyAskedQuestions) return;
  let faqItems =
    frequentlyAskedQuestions && parseFAQs(frequentlyAskedQuestions || "");

  let transformedFaqItems = Array.isArray(faqItems)
    ? faqItems.map((item) => (
        <AccordionTextItem key={item.question} trigger={item.question || ""}>
          <p className="mb-0">{item.answer}</p>
        </AccordionTextItem>
      ))
    : faqItems;

  if (hasOfacRestrictions) {
    if (Array.isArray(transformedFaqItems)) {
      transformedFaqItems.unshift(
        <AccordionTextItem trigger={staticOfacRestriction.trigger}>
          <p>{staticOfacRestriction.description} </p>
        </AccordionTextItem>,
      );
    } else {
      transformedFaqItems = `${transformedFaqItems} ${OFACText}`;
    }
  }

  if (transformedFaqItems?.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-6">
        <h2 className="my-0">Frequently asked questions</h2>
        {Array.isArray(transformedFaqItems) ? (
          <AccordionText>{transformedFaqItems}</AccordionText>
        ) : typeof transformedFaqItems === "string" ? (
          <div className="prose" style={{ wordBreak: "keep-all" }}>
            {parse(transformedFaqItems)}
          </div>
        ) : null}
      </div>
    </div>
  );
}
