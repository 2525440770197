import React from "react";
import Icon from "@/components/icons";

export interface CertificateInfoProps {
  title: string;
  details: {
    heading: string;
    description: string;
  }[];
}

export default function CertificateInfo({
  title,
  details,
}: CertificateInfoProps) {
  return (
    <div className="flex flex-col gap-10">
      <h2 className="my-0 text-putty-300 italic text-3xl md:text-4xl tracking-[-0.06rem]">
        {title}
      </h2>
      <ul className="flex flex-col gap-4 text-white">
        {details?.map((item) => (
          <li key={item.heading} className="flex items-start gap-4">
            <div className="w-5 h-6 translate-y-1">
              <Icon
                type="circle-check-outline"
                svgProps={{ width: "20", height: "20" }}
                pathProps={{ fill: "white" }}
              />
            </div>
            <div className="text-base">
              <b>{item.heading}</b>
              {item.description}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
