"use client";
import React, { useState } from "react";
import hasCourseRunStarted from "@/lib/course/utils/hasCourseRunStarted";
import {
  AUTH_DOMAIN,
  LMS_COURSE_DOMAIN,
  LMS_DOMAIN,
} from "@/lib/course/utils/constants";
import { cn } from "@/lib/utils";
import { CourseEnrollLinkProps } from "@/components/CourseContent/EnrollButton/types";
import useCourseEnrollment from "../hooks/useCourseEnrollment";
import { enrollButtonBaseStyles } from "./constants";
import EnterpriseNotificationModal from "@/components/CourseContent/ContentRefresh/EnterpriseNotificationModal";
import { ElementType } from "@/constants/tagular/main";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { getEnrollButtonText } from "@/lib/course/utils/getCourseRunEnrollButtonText";

const baseStyles = `${enrollButtonBaseStyles} bg-brand text-white border-2 border-brand hover:bg-white hover:text-brand hover:border-brand`;

function EnrollLink({
  buttonClass = "",
  courseRun,
  course,
  location = "",
}: CourseEnrollLinkProps) {
  const [isDisabled, setDisabled] = useState(false);
  const [isOpenEnterpriseModal, setIsOpenEnterpriseModal] = useState(false);
  const {
    isLoggedIn,
    isUserEnrolledInRun,
    isCourseEnrollable,
    handleEnrollment,
    commerceCoodrinatorUrl,
    triggerConversionEvent,
    paidLeadValues,
    emailOptIn,
  } = useCourseEnrollment({
    courseRun,
    course,
  });

  const loggedOutLinkRef = React.useRef<HTMLAnchorElement | null>(null);
  //TODO: Replace with real API DATA
  const isEnterpriseUser = false;

  const { linkDirectToPaymentPage, enrollCtaLabel } = paidLeadValues || {};

  const courseStarted = courseRun.start
    ? hasCourseRunStarted(courseRun.start)
    : false;

  const enrollButtonText = getEnrollButtonText(
    {
      enrollmentStart: courseRun.enrollmentStart,
      enrollmentEnd: courseRun.enrollmentEnd,
      end: courseRun.end,
    },
    isUserEnrolledInRun || false,
  );

  const ctaLabel =
    enrollButtonText === "Earn a certificate" && enrollCtaLabel
      ? enrollCtaLabel
      : undefined;

  const webElement: ElementClicked["webElement"] = {
    name: "course-conversion",
    elementType: ElementType.Button,
    htmlId: "earn-cert",
    location: location,
    text: enrollButtonText,
    position: "1",
  };

  const { elementClicked } = useElementEvents({
    webElement,
  });

  const fireCertificateEvent = () => {
    location === "cert-module"
      ? elementClicked({ webElement: webElement })
      : null;
  };

  const onEnroll = () => {
    if (!isDisabled) {
      setDisabled(true);
      fireCertificateEvent();
      if (isEnterpriseUser) {
        setIsOpenEnterpriseModal(true);
      } else {
        handleEnrollment();
      }
    }
  };

  return (
    <>
      {!isLoggedIn && isCourseEnrollable && (
        <a
          ref={loggedOutLinkRef}
          onClick={triggerConversionEvent}
          href={
            linkDirectToPaymentPage
              ? commerceCoodrinatorUrl
              : `${AUTH_DOMAIN}/register?course_id=${encodeURIComponent(courseRun.key)}&enrollment_action=enroll&email_opt_in=${emailOptIn}`
          }
          className={cn(baseStyles, buttonClass)}
        >
          {ctaLabel ?? enrollButtonText}
        </a>
      )}
      {isLoggedIn && !isUserEnrolledInRun && isCourseEnrollable && (
        <button
          type="button"
          className={cn(baseStyles, buttonClass)}
          onClick={onEnroll}
          disabled={isDisabled}
          id={courseRun.key}
        >
          {ctaLabel ?? enrollButtonText}
        </button>
      )}
      {isLoggedIn && isUserEnrolledInRun && (
        <a
          href={
            courseStarted
              ? `${LMS_COURSE_DOMAIN}/course/${encodeURIComponent(courseRun.key)}/home`
              : `${LMS_DOMAIN}/dashboard`
          }
          className={cn(baseStyles, buttonClass)}
          onClick={fireCertificateEvent}
        >
          {enrollButtonText}
        </a>
      )}
      <EnterpriseNotificationModal
        closeModal={() => {
          setIsOpenEnterpriseModal(false);
          setDisabled(false);
        }}
        isOpen={isOpenEnterpriseModal}
        onContinue={onEnroll}
      />
    </>
  );
}

export default EnrollLink;
