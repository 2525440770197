"use client";
import { Entitlement } from "@/lib/partner-api/getEntitlements";
import { useCourseContext } from "..";

const useUserEntitlements = () => {
  const { dispatch, state } = useCourseContext();

  const setUserEntitlements = (entitlements: Entitlement[]) => {
    dispatch({ type: "SET_USER_ENTITLEMENTS", payload: entitlements });
  };

  const setUserEntitled = (isUserEntitled: boolean) => {
    dispatch({ type: "SET_IS_USER_ENTITLED", payload: isUserEntitled });
  };

  return {
    setUserEntitlements,
    userEntitlements: state.userEntitlements,
    isUserEntitled: state.isUserEntitled,
    setUserEntitled,
  };
};

export default useUserEntitlements;
