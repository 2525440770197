import { CertificateInfoProps } from "../CertificateInfo";

export const defaultCertificateInfoData: CertificateInfoProps = {
  title: "Invest in your career",
  details: [
    {
      heading: "Career Boost: ",
      description:
        "edX certificates can unlock new oportunities and give you access to career resources.",
    },
    {
      heading: "Unlimited Access: ",
      description:
        "Come back for a quick learning refresh anytime throughout your career.",
    },
    {
      heading: "Learn at your own pace: ",
      description:
        "Life doesn't need to stop for you to advance your career. Learn from anywhere, anytime.",
    },
  ],
};

export const defaultTabCertificateInfoData: CertificateInfoProps = {
  title: "Invest in your career",
  details: [
    {
      heading: "Career Boost: ",
      description:
        "edX certificates can unlock new oportunities and give you access to career resources.",
    },
    {
      heading: "Unlimited Access: ",
      description:
        "Come back for a quick learning refresh anytime throughout your career.",
    },
    {
      heading: "Share your achievements: ",
      description:
        "Show off your dedication to continuous learning on your LinkedIn profile or resume.",
    },
    {
      heading: "Learn at your own pace: ",
      description:
        "Life doesn't need to stop for you to advance your career. Learn from anywhere, anytime.",
    },
  ],
};
