type LanguageMapping = {
  en: string;
  es: string;
};

type SubjectToTopicSlugs = {
  [key: string]: LanguageMapping;
};

const subjectToTopicSlugs: SubjectToTopicSlugs = {
  architecture: {
    en: "architecture",
    es: "arquitectura",
  },
  "art-culture": {
    en: "art",
    es: "arte",
  },
  "biology-life-sciences": {
    en: "biology",
    es: "biologia",
  },
  "business-management": {
    en: "business-administration",
    es: "administracion-de-empresas",
  },
  chemistry: {
    en: "chemistry",
    es: "quimica",
  },
  communication: {
    en: "business-communications",
    es: "comunicacion",
  },
  "computer-science": {
    en: "computer-programming",
    es: "programacion-informatica",
  },
  "data-science": {
    en: "data-analysis",
    es: "analisis-de-datos",
  },
  design: {
    en: "design",
    es: "diseno",
  },
  "economics-finance": {
    en: "economics",
    es: "economia",
  },
  "education-teacher-training": {
    en: "education",
    es: "educacion",
  },
  electronics: {
    en: "electronics",
    es: "ingenieria-electrica",
  },
  "energy-earth-sciences": {
    en: "energy",
    es: "energia",
  },
  engineering: {
    en: "engineering",
    es: "ingenieria",
  },
  "environmental-studies": {
    en: "environmental-science",
    es: "medio-ambiente",
  },
  ethics: {
    en: "ethics",
    es: "desafios-eticos",
  },
  "food-nutrition": {
    en: "nutrition",
    es: "nutricion",
  },
  "health-safety": {
    en: "healthcare",
    es: "cuidado-de-la-salud",
  },
  history: {
    en: "history",
    es: "historia",
  },
  humanities: {
    en: "humanities",
    es: "humanidades",
  },
  language: {
    en: "language",
    es: "idiomas",
  },
  law: {
    en: "law",
    es: "leyes",
  },
  literature: {
    en: "literature",
    es: "literatura",
  },
  math: {
    en: "math",
    es: "matematicas",
  },
  medicine: {
    en: "medicine",
    es: "medicina",
  },
  music: {
    en: "music-arts",
    es: "musica",
  },
  philanthropy: {
    en: "humanities",
    es: "humanidades",
  },
  "philosophy-ethics": {
    en: "ethics",
    es: "filosofia",
  },
  physics: {
    en: "physics",
    es: "fisica",
  },
  science: {
    en: "science",
    es: "ciencias-naturales",
  },
  "social-sciences": {
    en: "social-science",
    es: "ciencias-sociales",
  },
  "data-analysis-statistics": {
    en: "data-analysis-statistic",
    es: "analisis-de-datos",
  },
};

const getTopicUrl = (
  locale: string,
  subjectSlug: keyof SubjectToTopicSlugs,
) => {
  const topicSlug =
    subjectToTopicSlugs[subjectSlug]?.[locale as keyof LanguageMapping] || "";
  const topicUrl =
    locale === "es" ? `/es/aprende/${topicSlug}` : `/learn/${topicSlug}`;
  return topicUrl;
};

export default getTopicUrl;
