import React from "react";
import parse from "html-react-parser";
import ExpandableBlock from "@/components/ContentBlock/variants/ExpandableBlock";

export interface Props {
  additionalInformation: string;
}

export default function MoreAboutCourse({ additionalInformation }: Props) {
  return (
    <div className="flex flex-col gap-6">
      <h2 className="m-0">More about this course</h2>
      <ExpandableBlock
        blur
        maxHeight={150}
        btnClassName="text-gray-800 text-[18px] font-medium mt-4 underline-offset-4"
        blurClassName="from-putty-100 via-putty-100/80"
      >
        <div
          className="prose [&_*]:text-gray-800 [&>*:last-child]:m-0 [&>ul>li:last-child>*:last-child]:m-0"
          style={{ wordBreak: "keep-all" }}
        >
          {parse(additionalInformation)}
        </div>
      </ExpandableBlock>
    </div>
  );
}
