import Image from "next/image";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import icon from "@/public/icons/course-heading-icon.svg";
import CourseRating from "../CourseRating";
import parse from "html-react-parser";
import { Video } from "@/lib/course/types";
import VideoPlayer from "@/components/VideoPlayer";
import CirclePlay from "@/components/icons/CirclePlay";
import { useState } from "react";
import { PartialPartner } from "@/lib/partner/types";
import Link from "next/link";

type CourseHeaderProps = {
  title: string;
  subtitle: string;
  path: string;
  courseImage: string;
  partners: PartialPartner[];
  courseReview: {
    count: number;
    average: number;
  } | null;
  video?: Video;
};

export default function CourseHeader({
  title,
  subtitle,
  path,
  courseImage,
  partners,
  courseReview,
  video,
}: CourseHeaderProps) {
  const [showVideo, setShowVideo] = useState(false);
  const partnerImageDimensions = {
    width: 160,
    height: 48,
  };
  const courseHeaderIconDimensions = {
    width: 78,
    height: 92,
  };
  const courseHeaderImageDimensions = {
    width: 378,
    height: 378,
  };

  const videoOrImageJSX = () => {
    if (video && video.src) {
      return (
        <VideoPlayer
          videoClassName="w-full h-44"
          videoContainerClassName="w-[335px] h-44 mb-5"
          title={title}
          videoThumbnail={courseImage}
          videoUrl={video.src || ""}
        />
      );
    }
    if (courseImage) {
      return (
        <Image
          width={courseHeaderImageDimensions.width}
          height={courseHeaderImageDimensions.height}
          src={courseImage || ""}
          alt={title || ""}
          title={title || ""}
          className="object-contain h-auto sm:w-full md:w-[378px]"
        />
      );
    }
    return null;
  };

  return (
    <div className="bg-[#fbfaf9] sm:bg-gray-50 md:pt-8 pt-20 sm:pt-20 justify-center flex pb-4 md:pb-16">
      <div className="text-putty-foreground px-4 max-w-[1228px] w-full">
        <div className="pt-3 text-light-foreground">
          <Breadcrumbs
            variant="dark"
            path={path || ""}
            pageName={title || ""}
            linksToShowOnSmallScreen={1}
          />
        </div>
        <div className="flex relative w-full h-auto mt-2.5 mt-sm-3 mb-2">
          {partners.map((partner: PartialPartner) => {
            return partner.showOnSchoolsAndPartnersPage ? (
              <Link
                className="d-inline-block"
                key={partner.key}
                href={`/school/${partner.slug}`}
              >
                <Image
                  src={partner.logoUrl || ""}
                  width={partnerImageDimensions.width}
                  height={partnerImageDimensions.height}
                  alt={title || ""}
                  title={title || ""}
                  className="object-contain h-[48px] w-auto max-w-[160px]"
                  key={partner.key}
                />
              </Link>
            ) : (
              <Image
                src={partner.logoUrl || ""}
                width={partnerImageDimensions.width}
                height={partnerImageDimensions.height}
                alt={title || ""}
                title={title || ""}
                className="object-contain h-[48px] w-auto max-w-[160px]"
                key={partner.key}
              />
            );
          })}
        </div>

        <div className="hidden md:block absolute translate-x-[-70px]">
          <Image
            src={icon}
            width={courseHeaderIconDimensions.width}
            height={courseHeaderIconDimensions.height}
            data-ot-ignore
            className="optanon-category-C0001"
            alt="decorator"
            title="decorator"
          />
        </div>

        <div className="grid grid-cols-12 gap-4 items-start">
          <div className="col-span-12 md:col-span-7">
            <h1 className="text-learn-course-hero-heading mt-0 pt-0 mb-2 tracking-[-1.2px] leading-learn-course-hero-line-height font-bold">
              {title}
            </h1>
            {courseReview && <CourseRating {...courseReview} />}
            <div className="max-w-[984px] text-base">
              <span>{parse(subtitle)}</span>
            </div>
          </div>

          <div className="hidden min-[576px]:block col-span-12 md:col-span-5 flex justify-center w-full items-center">
            <div
              className={`w-full h-full flex justify-center items-center lg:max-h-[212px] ${
                video
                  ? "min-[576px]:justify-start md:items-start"
                  : "min-[576px]:justify-end md:items-end"
              }`}
            >
              {videoOrImageJSX()}
            </div>
          </div>

          {video && video.src && (
            <div className="block col-span-12 min-[576px]:hidden w-full">
              {!showVideo ? (
                <button
                  onClick={() => setShowVideo(true)}
                  className="w-full flex items-center justify-center gap-2 text-[18px] font-medium py-2 px-3 bg-white hover:bg-primary-500 hover:text-white text-primary-500 border border-gray-100"
                >
                  <CirclePlay svgProps={{ width: "24", height: "24" }} />
                  <span>Watch the video</span>
                </button>
              ) : (
                <div className="w-full flex justify-center items-center">
                  <VideoPlayer
                    videoClassName="w-full h-44"
                    videoContainerClassName="w-full h-44"
                    title={title}
                    videoThumbnail={courseImage}
                    videoUrl={video.src || ""}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
