"use client";
import React, { FC } from "react";
import styles from "./styles.module.css";
import IconTextList from "@/components/TextLists/IconTextList/IconTextList";
import IconTextListItem from "@/components/TextLists/IconTextList/IconTextListItem";
import { getCourseInformation } from "@/lib/course/utils/getCourseInformation";
import { ProcessedCourse } from "@/lib/course/types";
import { cn } from "@/lib/utils";
import { Skeleton } from "@/components/ui/skeleton";
import useLeadWithPaid from "@/monarch/rules/leadWithPaid/useLeadWithPaid";

export type Props = {
  course: ProcessedCourse;
  paymentOption: string | null;
  className?: string;
  hasDateColumn?: boolean;
  isLoading?: boolean;
  isArchivedExternally?: boolean;
};

const Loader: FC = () =>
  Array.from({ length: 3 }).map((_, i) => (
    <div key={i} className="flex items-center space-x-4 mr-36">
      <Skeleton className="h-12 w-12 rounded-full" />
      <div className="space-y-2">
        <Skeleton className="h-2 w-[150px]" />
        <Skeleton className="h-2 w-[200px]" />
      </div>
    </div>
  ));

export default function CourseSnapshot({
  course,
  paymentOption,
  className,
  hasDateColumn,
  isArchivedExternally = false,
}: Props) {
  const courseInformation = getCourseInformation(
    course,
    paymentOption,
    hasDateColumn,
    isArchivedExternally,
  );
  const classes = cn(styles.infocontainer__background, className);

  const { value: leadWithPaidTest, isLoading } = useLeadWithPaid(course);

  const overview = leadWithPaidTest?.overview ?? {};

  const IconTextListItems: FC = () =>
    courseInformation.map((item, key) => {
      const k = key >= 2 && hasDateColumn ? key - 1 : key;

      return (
        <IconTextListItem
          key={key}
          icon={overview[k]?.overviewIcon ?? item.icon}
          header={overview[k]?.overviewHeader ?? item.header}
          boldHeader={true}
          description={overview[k]?.overviewDescription ?? item.description}
          small
          className="grow-0"
          link={overview[k]?.overviewLink}
        />
      );
    });

  return (
    <div className={classes}>
      <div className="flex max-w-[1228px] mx-auto w-full">
        <IconTextList className={styles.infocontainer} small>
          {isLoading ? <Loader /> : <IconTextListItems />}
        </IconTextList>
      </div>
    </div>
  );
}
