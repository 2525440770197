"use client";
import { ProcessedCourse } from "@/lib/course/types";
import CourseEnroll from "../CourseEnroll";

type Props = {
  course: ProcessedCourse;
};

export default function CourseRunSelector({ course }: Readonly<Props>) {
  const multipleRunsAvailable = (course.activeCourseRuns || []).length > 1;

  const sortedCourseRuns = course.activeCourseRuns?.sort((crA, crB) => {
    if (!crA.start && !crB.start) return 0;
    if (!crA.start) return 1;
    if (!crB.start) return -1;

    const startComparison =
      new Date(crA.start).getTime() - new Date(crB.start).getTime();

    // If start dates are equal, sort by end date
    if (startComparison === 0) {
      if (crA.end && crB.end) {
        return new Date(crA.end).getTime() - new Date(crB.end).getTime();
      }
      if (crA.end) return -1;
      if (crB.end) return 1;
      return 0;
    }

    return startComparison;
  });

  return (
    <div
      id="enroll"
      className={`flex flex-col xl:flex-row flex-wrap gap-6 ${multipleRunsAvailable ? "xl:w-full" : "w-auto"}`}
    >
      {sortedCourseRuns?.map((courseRun) => {
        return (
          <CourseEnroll
            key={courseRun.id}
            courseRun={courseRun}
            id={`course-run-selector-run-${courseRun.id}`}
            course={course}
            data-testid={`course-run-selector-run-${courseRun.id}`}
          />
        );
      })}
    </div>
  );
}
