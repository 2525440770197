import React from "react";
import Image from "next/image";
import { Award } from "@/types/Customizations";
import { CourseAwardsProps } from "./types";
import { fixUrlSrc } from "@/utils/functions/fixUrlSrc";

function CourseAwards({ awards, isSpanish }: CourseAwardsProps) {
  if (!awards || awards.length === 0) {
    return null;
  }

  const validAwards = awards.filter((award) => {
    const englishImage = award.englishImage?.[0];
    const spanishImage = award.spanishImage?.[0];

    return (
      englishImage?.fileUrl &&
      spanishImage?.fileUrl &&
      award.englishAltText &&
      award.spanishAltText
    );
  });

  if (validAwards.length === 0) {
    return null;
  }

  return (
    <div>
      <h2 className="mt-0 pt-0 mb-6">Awards</h2>
      <div className="flex flex-col items-center md:flex-row md:items-start gap-4 flex-wrap">
        {validAwards.map((award: Award) => {
          const image = isSpanish
            ? award.spanishImage?.[0]
            : award.englishImage?.[0];
          const altText = isSpanish
            ? award.spanishAltText
            : award.englishAltText;
          const orientationClass =
            award.orientation?.toLowerCase() || "default";
          return (
            <Image
              key={`award-${image?.fileFileName}`}
              // TODO: Remove https: prefix when we start using the url instead of the file.url
              src={fixUrlSrc(image?.fileUrl) || ""}
              alt={altText || "Award Image"}
              width={image?.fileImageWidth || 100}
              height={image?.fileImageHeight || 100}
              className={`storefront-course-award ${orientationClass}`}
              quality={50}
            />
          );
        })}
      </div>
    </div>
  );
}

export default CourseAwards;
