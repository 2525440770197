import { CTAProps } from "@/components/CTA/types";
import AccordionContent from "@/components/AccordionContent";
export default function CTASplit({ title, subtitle, children }: CTAProps) {
  return (
    <>
      <div className="hidden sm:block max-w-screen-xl py-0 m-0 w-full">
        <div className="flex flex-wrap text-left relative py-8 px-4 lg:p-0 max-w-full">
          <div className="relative px-4 grow-0 shrink-0 basis-full lg:basis-1/4 max-w-full lg:max-w-[25%]">
            <div className="lg:py-[50px] lg:px-0 lg:before:absolute lg:before:bottom-0 lg:before:right-[-23px] lg:before:top-0 lg:before:w-[500%] lg:before:-z-10 lg:before:-skew-x-12 lg:before:bg-putty-200">
              <h2 className="text-2xl text-primary text-left mt-0 mr-4 lg:mr-0 mb-2 lg:mb-1">
                {title}
              </h2>
            </div>
          </div>
          <div className="flex flex-col text-primary justify-center px-4 relative grow-0 shrink-0 basis-full lg:basis-3/4 max-w-full lg:max-w-[75%]">
            <div className="lg:ml-28">
              <p className="text-primary text-base mb-0">{subtitle}</p>
              <div className="mt-4 flex gap-4">{children}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex sm:hidden flex-col">
        <AccordionContent title="edX For Business">
          <div className="flex flex-col text-primary break-words justify-center">
            <p className="text-primary text-base mb-0">{subtitle}</p>
            <div className="text-center flex flex-col gap-2 mt-4">
              {children}
            </div>
          </div>
        </AccordionContent>
      </div>
    </>
  );
}
