import { ExecEdATFProps, UseExecEdATF } from "./types";
import { useMonarchRule } from "../MonarchRulesProvider";

const CUSTOM_RULE_ID = "execEdAtfTest";

const useExecEdATF: UseExecEdATF = () => {
  const { isLoading, value } = useMonarchRule<{
    data: ExecEdATFProps;
    variantCustomId: string;
  }>(CUSTOM_RULE_ID, {});

  if (isLoading) {
    return {
      isLoading,
      showTest: undefined,
      // conversionType: undefined,
    };
  }

  return {
    isLoading,
    showTest: value?.data?.showTest,
    // conversionType: value?.data?.eventing?.conversionType,
  };
};

export default useExecEdATF;
