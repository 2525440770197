import React from "react";
import { PartialProgram } from "@/lib/course/types";

interface FullProgramCTAProps {
  items: PartialProgram[];
}

function FullProgramCTA({ items }: FullProgramCTAProps) {
  if (items.length === 1) {
    return (
      <div className="bg-primary-500 py-14 px-4 md:px-16">
        <h2 className="text-putty-500 font-light text-2xl md:text-4xl mt-0 pt-0 mb-8">
          This course is part of
          <br />
          <span className="font-extrabold">
            {items[0].title} {items[0].type} Program
          </span>
        </h2>
        <a href={"/" + items[0].marketingUrl}>
          <button className="text-center content-center items-center min-w-full md:min-w-32 py-2 justify-center disabled:opacity-25 rounded-full px-6 bg-brand text-white border-2 border-brand hover:bg-white hover:text-brand hover:border-brand">
            <span>Learn more</span>
          </button>
        </a>
      </div>
    );
  }

  return (
    <div className="bg-putty-400 py-14 px-4 md:px-16">
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6">
        <div className="gap-4 place-content-center">
          <h2 className="text-4xl md:text-6xl italic text text-secondary-500 font-black font-inter mt-0 sm:pt-0">
            Get the full <br /> program
          </h2>
          <h3 className="text-lg font-bold font-inter leading-9">
            This course is part of the following programs
          </h3>
        </div>
        <div className="grid-cols-1 place-content-center">
          <ul className="md:gap-6 !list-none">
            {items.map((item, index) => (
              <li
                key={index}
                className={`font-inter underline text-base ${index !== items.length - 1 ? "mb-4" : ""}`}
              >
                <a href={"/" + item.marketingUrl} target="_blank">
                  {item.type} in {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FullProgramCTA;
