import { CTAComponentProps } from "@/components/CTA/types";
import ImageRight from "@/components/CTA/variants/ImageRight";
import ImageLeft from "@/components/CTA/variants/ImageLeft";
import Register from "@/components/CTA/variants/Register";
import CTASplit from "@/components/CTA/variants/Split";
import MultiCourse from "@/components/CTA/variants/MultiCourse";
import CTABanner from "@/components/CTA/variants/Banner";
import CopyRightPlusButton from "./variants/CopyRightPlusButton";
import DynamicProductList from "@/components/CTA/variants/DynamicProductList";
import ImageRightAboutUs from "./variants/ImageRightAboutUs";
import BannerProgramUpsell from "./variants/BannerProgramUpsell";

export default function CTA({
  variant = "banner",
  ...props
}: CTAComponentProps) {
  switch (variant) {
    case "register":
      return <Register {...props} />;
    case "split":
      return <CTASplit {...props} />;
    case "copy-right-plus-button":
      return <CopyRightPlusButton {...props} />;
    case "image-right":
      return <ImageRight {...props} />;
    case "image-left":
      return <ImageLeft {...props} />;
    case "banner":
      return <CTABanner {...props} />;
    case "multi-course":
      return <MultiCourse {...props} />;
    case "dynamic-product-list":
      return <DynamicProductList {...props} />;
    case "image-right-about-us":
      return <ImageRightAboutUs {...props} />;
    case "banner-product-upsell":
      return <BannerProgramUpsell {...props} />;
    default:
      return null;
  }
}
