import { Award } from "@/types/Customizations";

const awardHasRequiredData = (award: Award): boolean => {
  const { englishImage, spanishImage, englishAltText, spanishAltText } = award;

  return (
    Array.isArray(englishImage) &&
    englishImage.length > 0 &&
    typeof englishImage[0].fileUrl === "string" &&
    englishImage[0].fileUrl.trim().length > 0 &&
    Array.isArray(spanishImage) &&
    spanishImage.length > 0 &&
    typeof spanishImage[0].fileUrl === "string" &&
    spanishImage[0].fileUrl.trim().length > 0 &&
    typeof englishAltText === "string" &&
    englishAltText.trim().length > 0 &&
    typeof spanishAltText === "string" &&
    spanishAltText.trim().length > 0
  );
};

export default awardHasRequiredData;
