import { MIN_ENROLLMENT_COUNT_FOR_DISPLAY } from "../../CourseRunSelector/constants";
import { ProcessedCourse } from "@/lib/course/types";
import MidPageCTAEnrollment from "./MidPageCTAEnrollment";

export interface Props {
  course: ProcessedCourse;
  isArchived?: boolean;
}

export default function MidPageCTA({ course, isArchived }: Props) {
  const { enrollmentCount, activeCourseRun } = course || {};
  const showEnrollmentCount =
    !isArchived &&
    activeCourseRun?.isEnrollable &&
    enrollmentCount &&
    enrollmentCount >= MIN_ENROLLMENT_COUNT_FOR_DISPLAY;

  if (!course || !course.activeCourseRun) return;

  return (
    <div className="flex flex-col text-center gap-4">
      <h2 className="text-xl font-bold my-0">Ready to advance your career?</h2>
      {showEnrollmentCount ? (
        <div className="text-lg text-gray-800">
          <span className="font-bold">{enrollmentCount.toLocaleString()}</span>
          <span>&nbsp;learners enrolled</span>
        </div>
      ) : null}
      <div className="self-center">
        <MidPageCTAEnrollment course={course} />
      </div>
    </div>
  );
}
