import CertificateImage from "../../../CertificateImage";
import CertificateStats, {
  CertificateStatsProps,
} from "../../../CertificateStats";
import { defaultTabCertificateStatsData } from "../../../constants/certificateStats";

export default function CertificateStatsTab({
  externalData,
}: {
  externalData?: CertificateStatsProps;
}) {
  return (
    <>
      <CertificateStats
        title={externalData?.title ?? defaultTabCertificateStatsData.title}
        stats={externalData?.stats ?? defaultTabCertificateStatsData.stats}
      />
      <CertificateImage />
    </>
  );
}
