import * as React from "react";
import styles from "./styles.module.css";
import Icon from "@/components/icons";
import { WaysToTakeCourseProps } from "./types";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import getCourseRunExpirationDateString from "@/lib/course/utils/getCourseRunExpirationDateString";
import getCoursePrice from "@/lib/course/utils/getCoursePrice";
import CourseEnrollButton from "../EnrollButton";
import getCourseRunTilePrimaryText from "@/lib/course/utils/getCourseRunTilePrimaryText";

const COMPARISON_SECTIONS = [
  "World-class institutions and universities",
  "edX support",
  "Shareable certificate upon completion",
  "Graded assignments and exams",
];

function getInfoToolTip(text: string) {
  return (
    <Popover>
      <PopoverTrigger className="ml-2 relative top-1">
        <Icon type="info" />
      </PopoverTrigger>
      <PopoverContent className="bg-primary p-4 rounded-xl max-w-56 mx-auto text-white text-sm">
        {text}
      </PopoverContent>
    </Popover>
  );
}

export default function WaysToTakeCourse({
  activeCourseRun,
  course,
}: WaysToTakeCourseProps) {
  const expirationDate = getCourseRunExpirationDateString(activeCourseRun);
  const showAuditModeExpirationDate = true; //TODO: Get from context, look into ShowAuditModeExpirationDateContext in prospectus
  const coursePrice = getCoursePrice(activeCourseRun); //TODO: add user currency to this logic
  return (
    <div className="mx-auto pb-8 max-w-3xl md:px-4 lg:max-w-screen-xl lg:mt-14">
      <div className="flex flex-col lg:flex-row">
        <div
          className={`pt-2 md:pt-6 lg:w-1/4 ${styles.courseModesLeftPanel} lg:pr-6`}
        >
          <h2 className="hidden text-3xl md:block pt-6 mb-2">
            Ways to take this course
          </h2>
          <span className="text-base">Choose your path when you enroll.</span>
          <CourseEnrollButton
            id="ways-to-take-course"
            isSelfHiding
            courseRun={activeCourseRun}
            course={course}
            buttonClass="bg-primary hover:bg-primary-400"
            className="mt-4"
          >
            <div className="leading-4">
              <div className="text-white my-0 text-base font-bold">
                Enroll now
              </div>
              <div className="text-xs">
                {" "}
                {getCourseRunTilePrimaryText(activeCourseRun)}
              </div>
            </div>
          </CourseEnrollButton>
        </div>
        <div className="pt-4 md:pt-12 lg:w-3/4 lg:pl-16">
          <div className="grid grid-cols-[1fr_2fr_2fr] md:grid-cols-3 divide-y lg:px-16">
            {/* HEADER ROW */}
            <div className="col-start-2 justify-items-center !border-t-0 md:px-2">
              <div className="bg-accent-yellow w-full max-w-[220px] md:h-[100px] text-center content-center">
                <div className="text-base md:text-lg font-bold">
                  Certificate
                </div>
              </div>
            </div>
            <div className="justify-items-center !border-t-0 md:px-2">
              <div className="bg-putty-200 w-full max-w-[220px] md:h-[100px] text-center content-center">
                <div className="text-base md:text-lg font-bold">Free</div>
              </div>
            </div>
            {/* DATA COMPARISON ROWS */}
            <div className="content-center !border-t-0">
              <div className="text-sm md:text-base font-bold my-6">Price</div>
            </div>
            <div className="content-center text-center text-base md:text-lg !border-t-0">
              ${coursePrice} USD
            </div>
            <div className="content-center text-center text-base md:text-lg !border-t-0">
              -
            </div>
            <div className="content-center">
              <div className="text-sm md:text-base font-bold my-6">
                Access to course materials
              </div>
            </div>
            <div className="content-center text-center text-base md:text-lg">
              Unlimited
              {getInfoToolTip(
                `You will have unlimited access to the course material.
                After the course ends, you will still have access to the material, but you will no longer be able to submit graded assignments or earn a certificate.`,
              )}
            </div>
            <div className="content-center text-center text-base md:text-lg">
              Limited
              {getInfoToolTip(
                `In the free audit track you may not be able to access and complete course materials including, but not limited to, graded assignments and exams.`,
              )}
              {showAuditModeExpirationDate ? (
                <div className="text-xs text-gray mt-1">
                  Expires on {expirationDate}
                </div>
              ) : null}
            </div>
            {COMPARISON_SECTIONS.map((section, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="content-center">
                    <div className="text-sm md:text-base font-bold my-6">
                      {section}
                    </div>
                  </div>
                  <div className="content-center inline-grid text-center text-lg text-success-dark justify-items-center">
                    <div className="rounded-full bg-success-light">
                      <Icon type="check" />
                    </div>
                  </div>
                  {index < 2 ? (
                    <div className="content-center inline-grid text-center text-lg text-success-dark justify-items-center">
                      <div className="rounded-full bg-success-light">
                        <Icon type="check" />
                      </div>
                    </div>
                  ) : (
                    <div className="content-center inline-grid text-center text-lg justify-items-center">
                      <Icon type="dash" />
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          {/*  */}
          <div className="text-gray text-sm mt-2 lg:pl-16">
            <a
              className="mr-2 text-primary"
              href="https://support.edx.org/hc/en-us/sections/360002747833-About-Audit-and-Verified-Courses"
              target="_blank"
            >
              <span className="mr-1 underline underline-offset-2">
                Read our FAQs
              </span>
              <Icon
                type="launch"
                svgProps={{
                  width: 21,
                  height: 21,
                  style: { display: "inline", marginBottom: "4px" },
                }}
              />
            </a>
            <span>about frequently asked questions on these tracks.</span>
          </div>
        </div>
      </div>
    </div>
  );
}
