import Image from "next/image";
import { CTAProps } from "@/components/CTA/types";
import background from "@/public/bg-images/edx-b2b.webp";
import styles from "./ImageLeft.module.css";

export default function ImageLeft({
  title,
  subtitle,
  brandimage,
  children,
  imagealttext,
}: CTAProps) {
  return (
    <div className="w-full grid lg:grid-cols-2 bg-primary overflow-hidden h-auto items-center justify-center">
      <div className="hidden lg:block">
        <div className="w-full">
          <Image
            src={brandimage || background}
            width={600}
            height={600}
            alt={imagealttext ?? ""}
            className="max-w-[600px]"
            layout="responsive"
          />
        </div>
      </div>

      <div
        className={`${styles.imageLeftContainer} w-full justify-center lg:justify-between px-10 py-10 lg:py-20`}
      >
        <h1 className="lg:text-left p-0 m7-6 text-primary-foreground elm-d2 tracking-[-1.2px] font-extrabold italic">
          {title}
        </h1>
        <p className="font-bold">{subtitle}</p>
        {children}
      </div>
    </div>
  );
}
