import { PAYMENT_FREE, PAYMENT_PAID, PAYMENT_UPGRADABLE } from "./constants";

const getPaymentOptionFromSeatTypes = (seatTypes: Set<string>) => {
  if (seatTypes.has("audit") && seatTypes.size === 1) {
    return PAYMENT_FREE;
  }
  if (seatTypes.size > 0 && seatTypes.has("audit")) {
    return PAYMENT_UPGRADABLE;
  }
  if (seatTypes.size > 0) {
    return PAYMENT_PAID;
  }
  return null;
};

export default getPaymentOptionFromSeatTypes;
