import React from "react";
import { cn } from "@/lib/utils";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { ProcessedCourse } from "@/lib/course/types";
import { CourseRun } from "@/lib/courseRun/types";
import EnrollLink from "@/components/CourseContent/ContentRefresh/CourseEnroll/CourseEnrollButtons/EnrollLink";
import AuditLink from "@/components/CourseContent/ContentRefresh/CourseEnroll/CourseEnrollButtons/AuditLink";

interface TabFooterProps {
  tabs: { tabHeading: string }[];
  activeTab: { tabHeading: string };
  goToTabByIndex: (index: number) => void;
  goToTab: (direction: "next" | "previous") => void;
  course: ProcessedCourse;
  courseRun: CourseRun;
}

export default function CertificateTabsFooter({
  tabs,
  activeTab,
  goToTabByIndex,
  goToTab,
  course,
  courseRun,
}: TabFooterProps) {
  const activeIndex = tabs.findIndex(
    (tab) => tab.tabHeading === activeTab.tabHeading,
  );

  return (
    <div className="flex w-full">
      <div className="w-1/2 flex justify-center items-center gap-6">
        <button
          onClick={() => goToTab("previous")}
          className={activeIndex === 0 ? "invisible" : ""}
        >
          <ChevronLeftIcon
            className="w-6 h-6 text-white font-bold"
            style={{ strokeWidth: 1, stroke: "white" }}
          />
        </button>
        <div className="flex items-center gap-6">
          {tabs.map((tab, index) => (
            <div
              key={tab.tabHeading}
              onClick={() => goToTabByIndex(index)}
              className={cn(
                "w-3.5 h-3.5 rounded-full transition cursor-pointer",
                activeTab.tabHeading === tab.tabHeading
                  ? "bg-brand-500"
                  : "bg-white",
              )}
            />
          ))}
        </div>
        <button
          onClick={() => goToTab("next")}
          className={activeIndex === tabs.length - 1 ? "invisible" : ""}
        >
          <ChevronRightIcon
            className="w-6 h-6 text-white font-bold"
            style={{ strokeWidth: 1, stroke: "white" }}
          />
        </button>
      </div>
      <div className="w-1/2 flex justify-center items-center gap-2 pl-16">
        <EnrollLink
          buttonClass="flex-1"
          courseRun={courseRun}
          course={course}
        />
        <AuditLink courseRun={courseRun} course={course} className="flex-1" />
      </div>
    </div>
  );
}
