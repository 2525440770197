export const MAX_DISPLAYED_COURSES = 4;
export const MIN_ENROLLMENT_COUNT_FOR_DISPLAY = 5000;

export const hideEmailOptInForCourse = [
  // Add course UUIDs for which the opt-in email checkbox should be hidden along with their course numbers
  "9b6e2bc0-7b84-4d35-b3f6-e569ae31de87", // TI10
  "5447077d-cdee-4031-b52a-3bee82d5cc4b", // OSUTIHTM23
  "a07f0c58-b3c3-4a21-b0c7-4ae2cf400b3c", // OSUTIPy23
  "53f5b608-5471-4133-b595-0ce0ab076cda", // OSUTICyb23
  "4529c77a-6a05-480f-b949-93d0fbbecb3b", // UCITIHTM23
  "58eb03a7-7443-4a3f-bd7a-e6c7892e5daf", // UCITIPy23
  "8cdc315d-513c-4143-a820-1955ea13833e", // UCITICyb23
  "ae2e9a0f-7c9e-4adc-a314-991b4af388ff", // DUTIHTM23
  "e0fa13c2-d77f-46c3-8401-cb4517786b9e", // DUTIPy23
  "ca43e7c1-774d-4346-8fa9-850cce02cbbf", // DUTICyb23
  "66bf6fe4-706c-4526-aa29-2f6dd589225d", // UNHTIHTM23
  "78bd6afc-2d8a-476c-bba7-12a239665c72", // UNHTIPy23
  "c9aa6128-e9b1-42b8-b22a-42653c8b4f5a", // UCRTIHTM23
  "9b59ab7e-adf7-4d4b-8c17-ea124070abb0", // UCRTIPy23
  "1fc23ad1-6893-401b-9440-312f2adf77be", // UCRTICyb23
  "aa9342c2-cdf5-408c-92ad-de01e101851b", // TecTIHTM23
  "28f4f055-1f85-4656-86ec-d709614aeedd", // TecTIPy23
  "e16858a9-b954-4345-8408-ebba9285b18b", // TecTICyb23
  "b44d7aa4-6508-4f74-b167-1cff813d2a05", // VUTIHTM23
  "f1646458-bc6a-4b46-95a6-6e4e56c6de05", // VUTIPy23
  "ba31dcf6-022c-4cf0-b74a-32c7dd880f15", // VUTICyb23
  "eb954cca-7ed0-4055-9586-94e005854304", // CWRTIHTM23
  "697333dd-83f0-4a16-920b-064e71baa632", // CWRTIPy23
  "be55e79a-fa94-4904-affd-158f3e8f249d", // CWRTICyb23
  "f981a7b9-112f-4c35-a430-82b8432a1308", // UNCTIHTM23
  "a1a10bcd-e15b-4ce8-b0c1-e21ad2ef26b0", // UNCTIPy23
  "37a65570-9ba5-4149-9fda-12e8b26ad0b7", // UNCTICyb23
  "bc7cd9ae-7561-4c7c-b249-51de07790e10", // UNCCTIHT23
  "5137b357-4ada-4b79-bf81-551a78f6231e", // UNCCTIPy23
  "84dbae87-7af2-4d65-a401-dec6aca960ab", // UNCCTICy23
  "d581abca-2ff0-462b-9604-d0616aebce23", // GWTIHTM23
  "a6e97d57-f6c6-4509-893e-3d113b77f982", // GWTIPy23
  "8fb72da5-f313-4727-b4ad-54e24a219a01", // GWTICyb23
  "eb165ad4-640d-4428-85ee-04ddc53be764", // RiceTIHT23
  "c028f686-b190-4e5f-8099-2a160dcf86ba", // RiceTIPy23
  "8d66f213-6a56-4c8a-80f5-91d0781d4ce6", // RiceTICy23
  "c29317da-8ac6-4832-bcef-f6873167685f", // UTATIHT23
  "27d66237-e2c6-463d-a524-47aacb7374cf", // UTATIPy23
  "b39b1b8a-48a6-4284-85f7-7821a9e63b23", // UTATICy23
  "aa9c43ea-9931-4254-8564-15ec5b3370dd", // MonTIHTM23
  "444513ce-b75f-4762-ba00-3d01818af476", // MonTIPy23
  "1fd448e4-53e2-4378-9bf8-7a94ab8f30bd", // MonTICyb23
  "2ba55c69-eedb-416e-801b-a3206a0be1f8", // AdeTIHTM23
  "bc9aea37-57dd-402d-a3ed-1a10b1f483ab", // AdeTIPy23
  "067d65c1-92b3-47af-9eb2-1daeb4ffd2ed", // AdeTICyb23
  "2799403d-f620-4115-8068-76267fcabab9", // UWATIHTM23
  "708fd622-8f4d-4961-a0dc-e407f5e9245a", // UWATIPy23
  "5f04517f-8ea9-49d6-b0c9-11f550a46fb6", // UWATICyb23
  "6969d243-d4a7-4317-a961-f58f3ad1b790", // KUTIHTM23
  "d398c405-4104-4287-a700-f7fcbfd25fb5", // KUTIPy23
  "5dcb3f07-3117-42f5-8d92-34d21a1ae0db", // KUTICyb23
  "394aac0a-bcc5-4e55-9867-4074bca8f43c", // MSUTIHTM23
  "628a8fda-19b4-4d8b-9665-ea803f30f063", // MSUTIPy23
  "180e9929-cdb2-4e5f-b839-c42795b595dd", // MSUTICyb23
  "b973915d-98f4-495c-8dd9-eac4a5a8882a", // MinTIHTM23
  "226c6fae-1e87-4813-bad9-3e71a0c37730", // MinTIPy23
  "47d21fff-a3d9-49ba-b87b-8de60a4aa80e", // MinTICyb23
  "45cb37b5-a55e-4a67-924e-99de1aafcaa2", // UCFTIHTM23
  "ba11c1d6-e65d-47ab-88aa-ebb21d806ba9", // UCFTIPy23
  "5e5de79d-fdc3-45dc-8492-c239b52ee54e", // WisTIHTM23
  "a0e9b745-2a90-44b5-8b89-d53380cef9d7", // WisTIPy23
  "97c3a72b-c2a9-4d30-beac-b6a32b139bda", // ASUTIHTM23
  "a9451b44-9f40-4165-974d-2fa0cecae04d", // ASUTIPy23
  "09bc5b41-6ddc-4595-a2d0-794d3a63564b", // ASUTICyb23
  "7a2c15b7-7670-4dd0-8b8a-77622fa93725", // WUSTIHTM23
  "a0622aa2-fbdd-4745-98f1-fbf08a0077b7", // WUSTIPy23
  "be67c8f6-6e29-47ea-9370-a940285fa670", // WUSTICyb23
  "91229b5f-50e3-4104-b5a6-8cf101bdac32", // TCCTIPy23
  "2e84edc2-87d7-4694-a3fd-2938355820ae", // TCCTICyb23
  "90865a3d-904b-49fd-b700-f5ee675b0ac3",
  "9dd9cec9-249d-448f-a0ce-b07a92c73fec",
  "a17869fd-630f-4288-9699-92163a88baea",
  "ba7a8ff2-6e41-4f80-96a3-c01139023e26",
  "824cded2-11ef-49af-a9d0-25770babc77e",
  "895f6a41-00b9-42c0-8503-0d9e2e87d23b",
];
