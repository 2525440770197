import Image from "next/image";
import { CTAProps } from "@/components/CTA/types";
import background from "@/public/bg-images/edX-homepage-community.webp";

const ImageRightAboutUs = ({
  title,
  subtitle,
  brandimage,
  children,
}: CTAProps) => {
  return (
    <div className="bg-primary not-prose mx-break-out">
      <div className="container px-0">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between pl-3 pb-12">
          <div className="lg:max-w-xl">
            <p className="tracking-wide text-xs font-bold text-white mt-12 mb-2 font-roboto">
              {title}
            </p>
            <h2 className="text-xl font-bold text-white mt-0 pt-0 mb-6">
              {subtitle}
            </h2>
            {children}
          </div>

          <div className="hidden lg:block overflow-hidden self-baseline">
            <div className="w-[500px] h-[500px] overflow-hidden">
              <Image
                src={brandimage || background}
                alt="Description of image"
                width={500}
                height={500}
                className="object-cover my-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageRightAboutUs;
