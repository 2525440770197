"use client";
import React, { useEffect } from "react";
import ISO6391 from "iso-639-1";
import { ProcessedCourse } from "@/lib/course/types";
import AtAGlance from "@/components/CourseContent/AtAGlance";
import StickyNav from "@/components/CourseContent/StickyNav";
import Section from "@/components/CourseContent/Section";
import BusinessBlock from "@/components/CourseContent/BusinessBlock";
import SocialShare from "@/components/SocialShare";
import styles from "./styles.module.css";
import CTA from "@/components/CTA";
import { Program } from "@/lib/program/types";
import WaysToTakeCourse from "@/components/CourseContent/WaysToTakeCourse";
import getCoursePaymentOption from "@/lib/course/utils/getCoursePaymentOption";
import getExpertInstructionIcon from "@/lib/course/utils/getExpertInstructionIcon";
import { getProgramPacingTypeIcon } from "@/lib/course/utils/getProgramPacingTypeIcon";
import { getEstimationDurationIcon } from "@/lib/course/utils/getEstimationDurationIcon";
import CourseHeader from "@/components/CourseContent/CourseHeader";
import CourseSnapshot from "@/components/CourseContent/CourseSnapshot";
import CourseRunSelector from "@/components/CourseContent/CourseRunSelector/CourseRunSelector";
import InstructorsList from "@/components/InstructorList";
import OFACRestrictions from "@/components/CourseContent/OFACRestriction";
import awardHasRequiredData from "@/utils/functions/awardHasRequiredData";
import Image from "next/image";
import isCourseArchived from "@/lib/course/utils/isCourseArchived";
import formatTextWithLink from "@/utils/functions/formatTextWithLink";
import useUserEnrollments from "@/components/CourseContent/CourseContext/hooks/useUserEnrollments";
import useUserEntitlements from "@/components/CourseContent/CourseContext/hooks/useUserEntitlements";
import getAwardSize from "@/lib/course/utils/getAwardSize";
import CourseDescription from "@/components/CourseContent/CourseDescription";
import isUserEnrolled from "@/lib/course/utils/isUserEnrolled";
import useClientEnrollments from "@/lib/partner-api/useClientEnrollments";
import useClientEntitlements from "@/lib/partner-api/useClientEntitlements";
import { fixUrlSrc } from "@/utils/functions/fixUrlSrc";
import CertificateEmphasis from "@/components/CourseContent/ContentRefresh/Certificate/CertificateEmpashis";
import useCertificateEmphasisRedesign from "@/monarch/rules/certificateEmphasisRedesign/useCertificateEmphasisRedesign";
import { RedesignedTrackComparison } from "@/components/CourseContent/ContentRefresh/Certificate/constants/enums";
import CertificateEmphasisTabs from "@/components/CourseContent/ContentRefresh/Certificate/CertificateEmphasisTabs";
import CertificateWaysToTakeCourse from "@/components/CourseContent/ContentRefresh/Certificate/CertificateWaysToTakeCourse";
import EnrollLink from "@/components/CourseContent/ContentRefresh/CourseEnroll/CourseEnrollButtons/EnrollLink";
import AuditLink from "@/components/CourseContent/ContentRefresh/CourseEnroll/CourseEnrollButtons/AuditLink";

type Props = {
  course: ProcessedCourse;
  program?: Program | null;
  programPacingType?: string;
  programDuration?: string;
  hoursPerWeekFormatted?: string;
  paymentOption: "free" | "upgradable" | "paid" | null;
  showCourseModes: boolean;
  category?: string;
  courseSlug?: string;
};

const links = [
  {
    label: "About",
    path: "#about",
  },
  {
    label: "What you'll learn",
    path: "#what-youll-learn",
  },
  {
    label: "Syllabus",
    path: "#syllabus",
  },
  {
    label: "Instructors",
    path: "#instructors",
  },
  {
    label: "FAQs",
    path: "#faq",
  },
  {
    label: "Ways to enroll",
    path: "#ways-to-enroll",
  },
];

function getProgramUpsell(
  program: Program,
  programPacingType: string,
  programDuration: string,
  hoursPerWeekFormatted: string,
): React.ReactNode {
  return (
    <CTA
      variant="banner-product-upsell"
      title={`${program.title} ${program.productType}`}
      cta={{
        label: "Learn more",
        url: `/${program.marketingUrl}`,
      }}
    >
      <div className="flex flex-col flex-1 items-start justify-center text-left space-y-8 mt-8 ml-8 md:mt-0 md:ml-0">
        {getExpertInstructionIcon(program.courses.length, program.productType)}
        {programPacingType && getProgramPacingTypeIcon(programPacingType)}
        {getEstimationDurationIcon(programDuration, hoursPerWeekFormatted)}
      </div>
    </CTA>
  );
}

export default function OpenCourseContent({
  course,
  program = null,
  programPacingType = "",
  programDuration = "",
  hoursPerWeekFormatted = "",
  paymentOption,
  showCourseModes,
  category,
  courseSlug,
}: Props) {
  //fetch enrollment data
  const { enrollments: userEnrollments } = useClientEnrollments();

  //fetch entitlement data
  const { entitlements: userEntitlements } = useClientEntitlements();

  const { setIsUserEnrolled, setUserEnrollments } = useUserEnrollments();
  const { setUserEntitled } = useUserEntitlements();

  useEffect(() => {
    if (userEntitlements && userEntitlements.length > 0) {
      const found =
        userEntitlements?.some(
          ({ courseUuid: userEntitledCourseUuid }) =>
            userEntitledCourseUuid === course.activeCourseRun?.uuid,
        ) || false;
      setUserEntitled(found);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEntitlements]);

  //set context enrollmment status
  useEffect(() => {
    if (userEnrollments && userEnrollments.length > 0) {
      setUserEnrollments(userEnrollments);
      const courseIds = course.activeCourseRuns?.map(({ key }) => key) || [];
      const isEnrolled = isUserEnrolled(userEnrollments, courseIds);
      setIsUserEnrolled(isEnrolled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEnrollments]);
  const {
    emphasisModuleVersion,
    showRedesignedTrackComparison,
    information,
    stats,
    testimonial,
    tableDescription,
    tabsOrder,
  } = useCertificateEmphasisRedesign();

  const courseTitle = `${course.partners.map((obj) => obj.key).join(", ")}: ${course.productName}`;
  const partners = course.partners.length ? course.partners : [];
  const awards = course.customizations.catalogPage?.awards || [];
  const hasOfacRestrictions = course.hasOfacRestrictions;
  const video = course.video;
  const { activeCourseRun, activeCourseRuns } = course;
  const isArchived = activeCourseRun ? isCourseArchived(activeCourseRun) : true;
  const validLinks = links.filter(
    (navLink) =>
      !(
        (navLink.label === "About" && !course.productFullDescription) ||
        (navLink.label === "What you'll learn" && !course.productOverview) ||
        (navLink.label === "Syllabus" && !course.syllabusHtml) ||
        (navLink.label === "FAQs" && !course.frequentlyAskedQuestions) ||
        (navLink.label === "Ways to enroll" && !showCourseModes) ||
        (navLink.label === "Instructors" &&
          activeCourseRun &&
          !(
            Object.keys(activeCourseRun).length > 0 &&
            activeCourseRun.instructors.length > 0
          ))
      ),
  );

  return (
    <div className={styles.container}>
      <CourseHeader
        title={courseTitle}
        subtitle={course.productShortDescription}
        path={course.productUrlSlug}
        courseImage={course.productImageUrl}
        partners={partners}
        courseReview={course.ratings}
        video={video}
      />
      <CourseSnapshot
        course={course}
        paymentOption={paymentOption}
        className="hidden md:block -mt-8"
      />
      <div className="max-w-[1228px] mx-auto">
        <CourseRunSelector
          course={course}
          id={course.productUuid}
          activeCourseRun={activeCourseRun}
          activeCourseRuns={activeCourseRuns}
          paymentOption={getCoursePaymentOption(course) || ""}
          partners={partners}
          enrollmentCount={course.enrollmentCount}
          category={category}
          courseSlug={courseSlug}
        />
      </div>
      <StickyNav
        course={course}
        links={validLinks}
        courseRun={course.activeCourseRun}
      />
      <div className={styles.courseDetailCenteredContent}>
        <Section link="about" title="About this course">
          <CourseDescription
            courseData={course}
            paymentOption={paymentOption}
          />
          {showCourseModes &&
            !isArchived &&
            course?.activeCourseRun?.isEnrollable &&
            emphasisModuleVersion &&
            emphasisModuleVersion !== RedesignedTrackComparison.Hidden && (
              <div className={`${styles.noStyle} mt-5`}>
                {emphasisModuleVersion === RedesignedTrackComparison.Static ? (
                  <CertificateEmphasis
                    course={course}
                    courseRun={course?.activeCourseRun}
                    information={information}
                    stats={stats}
                    testimonial={testimonial}
                  />
                ) : (
                  <CertificateEmphasisTabs
                    course={course}
                    courseRun={course?.activeCourseRun}
                    information={information}
                    stats={stats}
                    testimonial={testimonial}
                    tabOrder={tabsOrder}
                  />
                )}
              </div>
            )}
          {awards.length > 0 && awardHasRequiredData(awards[0]) && (
            <div className="py-4 mt-2 block">
              <h3 className="text-lg text-black">Awards</h3>
              <div className="flex items-center mt-3 space-x-4">
                {awards.map((award) => {
                  if (awardHasRequiredData(award)) {
                    const imageProps = award.englishImage?.[0];
                    const altText = award.englishAltText;
                    const awardSize = getAwardSize(award);

                    return (
                      <Image
                        key={`award-${award.awardEntryId ?? award.name ?? Math.random()}`}
                        // TODO: Remove https: prefix when we start using the url instead of the file.url
                        src={fixUrlSrc(imageProps?.fileUrl)}
                        alt={altText ?? ""}
                        title={altText ?? ""}
                        width={awardSize.width}
                        height={awardSize.height}
                        quality={50}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          )}
          <AtAGlance
            institutions={course.partners}
            subjects={course.subjects}
            level={course.levelType}
            prerequisites={
              course.prerequisitesHtml.length > 0
                ? formatTextWithLink(course.prerequisitesHtml)
                : "None"
            }
            language={
              course?.activeCourseRun?.languageId
                ? ISO6391.getNativeName(
                    course?.activeCourseRun?.languageId.slice(0, 2),
                  )
                : ""
            }
            videoTranscript={
              course.activeCourseRun?.transcriptLanguages
                ? course.activeCourseRun?.transcriptLanguages
                    .map((language) =>
                      ISO6391.getNativeName(language.slice(0, 2)),
                    )
                    .join(", ")
                : ""
            }
            associatedPrograms={course.programs ?? []}
            associatedSkills={course.associatedSkills?.join(", ")}
          />
        </Section>
        {course.productOverview && (
          <Section
            link="what-youll-learn"
            title="What you'll learn"
            parseContent={course.productOverview}
          />
        )}
        {course.syllabusHtml && (
          <Section
            link="syllabus"
            title="Syllabus"
            parseContent={course.syllabusHtml}
          />
        )}
        {course.learnerTestimonials && (
          <Section
            link="learner-testimonials"
            title="Learner testimonials"
            parseContent={course.learnerTestimonials}
          />
        )}
      </div>
      <div className="mx-auto max-w-3xl md:px-4 lg:max-w-screen-xl">
        {activeCourseRun?.instructors && activeCourseRun?.instructors.length ? (
          <Section
            link="instructors"
            title="About the instructors"
            titleMobile="Instructors"
            titleClassName="text-center mb-4"
            bottomComponent={
              program
                ? getProgramUpsell(
                    program,
                    programPacingType,
                    programDuration,
                    hoursPerWeekFormatted,
                  )
                : null
            }
            bottomFullWidthMobile={true}
          >
            <InstructorsList
              variant="default"
              instructors={activeCourseRun.instructors}
            />
          </Section>
        ) : null}
      </div>
      <div className={styles.courseDetailCenteredContent}>
        {course.frequentlyAskedQuestions && (
          <Section
            link="faq"
            title="Frequently Asked Questions"
            parseContent={course.frequentlyAskedQuestions || ""}
            bottomComponent={
              hasOfacRestrictions ? (
                <OFACRestrictions className="block md:hidden" />
              ) : null
            }
          />
        )}
        {hasOfacRestrictions && (
          <>
            <div className="hidden md:block">
              <Section>
                <OFACRestrictions />
              </Section>
            </div>
            {!course.frequentlyAskedQuestions && (
              <div className="block md:hidden">
                <Section link="faq" title="Frequently Asked Questions">
                  <OFACRestrictions />
                </Section>
              </div>
            )}
          </>
        )}
        {course.additionalInformation && course.frequentlyAskedQuestions && (
          <Section
            link="aditional-information"
            title="More about this course"
            parseContent={course.additionalInformation}
          />
        )}
      </div>
      {program && (
        <div className="hidden md:block lg:-mb-14">
          {getProgramUpsell(
            program,
            programPacingType,
            programDuration,
            hoursPerWeekFormatted,
          )}
        </div>
      )}
      {showCourseModes &&
      !isArchived &&
      course?.activeCourseRun?.isEnrollable ? (
        showRedesignedTrackComparison ? (
          <Section
            title="Ways to take this course"
            titleClassName="sm:hidden"
            link="ways-to-enroll"
            className={`mb-10 ${program ? "mt-28" : activeCourseRun?.instructors ? "mt-14" : ""}`}
          >
            <div className={styles.courseDetailCenteredContent}>
              <CertificateWaysToTakeCourse
                activeCourseRun={course.activeCourseRun}
                tableDescription={tableDescription}
              >
                <div className="flex flex-col md:flex-row gap-2 sm:gap-3 items-center">
                  <EnrollLink
                    buttonClass="flex-1"
                    courseRun={course.activeCourseRun}
                    course={course}
                  />
                  <AuditLink
                    courseRun={course.activeCourseRun}
                    course={course}
                    className="flex-1"
                  />
                </div>
              </CertificateWaysToTakeCourse>
            </div>
          </Section>
        ) : (
          <Section
            title="Ways to take this course"
            titleClassName="sm:hidden"
            link="ways-to-enroll"
          >
            <WaysToTakeCourse
              activeCourseRun={course.activeCourseRun}
              course={course}
            />
          </Section>
        )
      ) : null}
      <Section title="Share this course" showSectionTitle={false}>
        <SocialShare
          productUrl={course.productUrl}
          productName={course.productName}
          className={
            !showCourseModes || !course.activeCourseRun ? "lg:mt-14" : ""
          }
        />
      </Section>
      <Section title="edX For Business" showSectionTitle={false}>
        <BusinessBlock
          courseName={courseTitle}
          seats={activeCourseRun?.seats || []}
          isArchived={isArchived}
        />
      </Section>
    </div>
  );
}
