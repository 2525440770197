import { ProcessedCourse } from "@/lib/course/types";
import getPaymentOptionFromSeatTypes from "./getPaymentOptionFromSeatTypes";

const getCoursePaymentOption = (course: ProcessedCourse) => {
  const courseRuns = course.activeCourseRuns;
  const seatTypes: Set<string> = new Set();

  courseRuns?.forEach((courseRun) => {
    if (courseRun.seats) {
      courseRun.seats.forEach((seat) => {
        seatTypes.add(seat.type);
      });
    }
  });

  return getPaymentOptionFromSeatTypes(seatTypes);
};

export default getCoursePaymentOption;
