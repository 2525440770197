import { CTAProps } from "../types";
import CTALink from "./CtaLink";

export default function BannerProgramUpsell({
  title,
  cta,
  children,
}: CTAProps) {
  return (
    <div className="lg:flex relative overflow-hidden py-16 mt-12 bg-primary-400 text-white mx-auto w-full lg:pl-[10vw]">
      <div className="basis-1/2 lg:border-r-[8px] lg:border-primary-300 lg:last:border-0 lg:-skew-x-12">
        <div className="px-12 lg:skew-x-12">
          <div className="flex flex-col flex-1 lg:items-start justify-center text-left">
            <span className="text-putty-200 scroll-m-20 leading-tight text-[40px] font-bold mb-8">
              <span>This course is part of</span>
              <span className="text-accent-yellow"> {title} Program</span>
            </span>
            {cta && (
              <CTALink
                type="brand"
                label={cta.label}
                url={cta.url}
                icon="arrow-right"
              />
            )}
          </div>
        </div>
      </div>
      <div className="md:mt-8 lg:mt-0 lg:pl-24 md:pl-12">{children}</div>
    </div>
  );
}
