import CertificateImage from "../../../CertificateImage";
import CertificateTestimonial, {
  CertificateTestimonialProps,
} from "../../../CertificateTestimonial";
import { defaultTabTestimonialData } from "../../../constants/certificateTestimonial";

export default function CertificateTestimonialTab({
  externalData,
}: {
  externalData?: CertificateTestimonialProps;
}) {
  return (
    <>
      <CertificateTestimonial
        title={externalData?.title ?? defaultTabTestimonialData.title}
        testimonial={
          externalData?.testimonial ?? defaultTabTestimonialData.testimonial
        }
        attribution={
          externalData?.attribution ?? defaultTabTestimonialData.attribution
        }
      />
      <CertificateImage />
    </>
  );
}
