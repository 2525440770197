"use client";
import { useState, useLayoutEffect, createRef } from "react";
type Props = {
  children: React.ReactNode;
};
export default function Expand({ children }: Props) {
  const ref = createRef<HTMLDivElement>();
  const [isOverflow, setIsOverflow] = useState<Boolean>(false);
  const [showMore, setShowMore] = useState<Boolean>(false);

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [ref]);

  return (
    <div className="relative pt-2">
      <div
        className={`relative flex flex-col break-all ${
          showMore ? "" : "max-h-32 overflow-hidden"
        }`}
        style={{ transition: "max-height 0.5s ease" }}
        ref={ref}
      >
        {children}
        {isOverflow && (
          <div
            className="absolute h-8 w-full bottom-0 "
            style={{
              backgroundImage: "linear-gradient(180deg, #fff0, #fff)",
            }}
          />
        )}
      </div>
      {(isOverflow || showMore) && (
        <button
          className="underline pt-2 mt-2 text-black text-base"
          onClick={() => setShowMore(!showMore)}
        >
          Show {showMore ? "less" : "more"}
        </button>
      )}
    </div>
  );
}
