import { Children, isValidElement } from "react";
import { CTAProps } from "@/components/CTA/types";
import background from "@/public/bg-images/edX-homepage-community.webp";
import Image from "next/image";
import CTALink from "@/components/CTA/ctaLink";
import styles from "./index.module.css";

export default function ImageRight({
  title,
  children,
  cta,
  brandimage,
  theme = "default",
  imagealttext = "",
  fullsize,
}: CTAProps) {
  return (
    <div className={`fullwidth py-0 ${styles[theme]}`}>
      <div className="container overflow-hidden flex items-center ">
        <div
          className={`py-10 max-w-[984px] ${fullsize ? "lg:px-0" : "lg:px-0"}`}
        >
          <h2 className="font-inter text-[44px] leading-[3rem] lg:text-5xl mb-7 text-secondary font-black italic tracking-[-0.06rem]">
            {title}
          </h2>
          {Children.toArray(children).filter((child) => isValidElement(child))}
          {cta && <CTALink url={cta.url} label={cta.label} />}
        </div>
        <div className="my-0 h-full">
          <Image
            width={788}
            height={0}
            src={brandimage || background}
            alt={imagealttext ?? ""}
            className="hidden xl:block m-0 p-0 max-w-[700px] align-middle overflow-clip"
          />
        </div>
      </div>
    </div>
  );
}
