"use client";
import { useState, type JSX } from "react";
import {
  Tabs as TabsUI,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@/components/ui/tabs";
import { Card } from "@/components/ui/card";
import CertificateTabsFooter from "./components/CertificateTabsFooter";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { ProcessedCourse } from "@/lib/course/types";
import { CourseRun } from "@/lib/courseRun/types";
import EnrollLink from "../../../../CourseEnroll/CourseEnrollButtons/EnrollLink";
import AuditLink from "../../../../CourseEnroll/CourseEnrollButtons/AuditLink";

export type TabsItem = {
  tabHeading: string;
  content: JSX.Element;
};

export type TabsProps = {
  tabs: TabsItem[];
  course: ProcessedCourse;
  courseRun: CourseRun;
};

export default function CetificateTabs({ tabs, course, courseRun }: TabsProps) {
  const [activeTab, setActiveTab] = useState<TabsItem>(tabs[0]);

  const handleTabChange = (
    tabSlug: string,
    triggeredByValidAction: boolean,
  ) => {
    if (triggeredByValidAction) {
      const tab = tabs.find((tab) => getTabSlug(tab.tabHeading) === tabSlug);
      if (tab) setActiveTab(tab);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent, tabSlug: string) => {
    if (event.key === "Enter") {
      handleTabChange(tabSlug, true); // Valid action
    }
  };

  const handleClick = (tabSlug: string) => {
    handleTabChange(tabSlug, true); // Valid action
  };

  const goToTab = (direction: "next" | "previous") => {
    const currentIndex = tabs.findIndex(
      (tab) => tab.tabHeading === activeTab.tabHeading,
    );
    const newIndex =
      direction === "next"
        ? (currentIndex + 1) % tabs.length
        : (currentIndex - 1 + tabs.length) % tabs.length;

    setActiveTab(tabs[newIndex]);
  };

  const goToTabByIndex = (index: number) => {
    setActiveTab(tabs[index]);
  };

  return (
    <div className="not-prose">
      <div className="hidden lg:block">
        <TabsUI
          value={getTabSlug(activeTab.tabHeading)}
          className="border rounded-xl w-full"
          onValueChange={(tabSlug) => handleTabChange(tabSlug, false)}
        >
          <TabsList>
            {tabs.map((tab) => (
              <TabsTrigger
                key={getTabSlug(tab.tabHeading)}
                value={getTabSlug(tab.tabHeading)}
                onClick={() => handleClick(getTabSlug(tab.tabHeading))}
                onKeyDown={(event) =>
                  handleKeyDown(event, getTabSlug(tab.tabHeading))
                }
                className="bg-putty-400 text-black text-base py-2.5 px-4"
              >
                {tab.tabHeading}
              </TabsTrigger>
            ))}
          </TabsList>
          {tabs.map((tab) => (
            <TabsContent
              key={getTabSlug(tab.tabHeading)}
              value={getTabSlug(tab.tabHeading)}
              className="m-0"
            >
              <Card className="rounded-t-none min-h-[555px] flex flex-col justify-between bg-gradient-to-b from-secondary-500 to-primary-500 gap-4 p-16 pb-10 border-gray-500">
                <div className="flex gap-20">{tab.content}</div>
                <CertificateTabsFooter
                  tabs={tabs}
                  activeTab={activeTab}
                  goToTabByIndex={goToTabByIndex}
                  goToTab={goToTab}
                  course={course}
                  courseRun={courseRun}
                />
              </Card>
            </TabsContent>
          ))}
        </TabsUI>
      </div>

      <div className="block lg:hidden">
        <TabsUI
          value={getTabSlug(activeTab.tabHeading)}
          onValueChange={(tabSlug) => handleTabChange(tabSlug, false)}
        >
          <div className="flex items-center justify-between bg-secondary-500 p-4">
            <button
              onClick={() => goToTab("next")}
              className={`${
                tabs.findIndex(
                  (tab) => tab.tabHeading === activeTab.tabHeading,
                ) === 0
                  ? "invisible"
                  : ""
              }`}
            >
              <ChevronLeftIcon className="w-7 h-7 text-white" />
            </button>
            <div className="py-2">
              <p className="text-base text-white font-bold m-0">
                {activeTab.tabHeading}
              </p>
            </div>
            <button onClick={() => goToTab("previous")}>
              <ChevronRightIcon className="w-7 h-7 text-white" />
            </button>
          </div>
          {tabs.map((tab) => (
            <TabsContent
              key={getTabSlug(tab.tabHeading)}
              value={getTabSlug(tab.tabHeading)}
              className="m-0"
            >
              <Card className="flex flex-col border-none gap-10 rounded-t-none bg-gradient-to-b from-secondary-500 to-primary-500 py-10 px-4">
                <div className="flex flex-col gap-10 items-center">
                  {tab.content}
                </div>
                <div className="w-full flex flex-col md:flex-row gap-3">
                  <EnrollLink
                    buttonClass="md:flex-1"
                    courseRun={courseRun}
                    course={course}
                  />
                  <AuditLink
                    courseRun={courseRun}
                    course={course}
                    className="md:flex-1"
                  />
                </div>
              </Card>
            </TabsContent>
          ))}
        </TabsUI>
      </div>
    </div>
  );
}

function getTabSlug(tabHeading: string) {
  return tabHeading.toLowerCase().replace(/\s/g, "-");
}
