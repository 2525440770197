import IconDescription from "@/components/IconDescription";
import { ProgramType } from "@/lib/program/types";

export default function getExpertInstructionIcon(
  courseCount: number,
  programType: ProgramType,
) {
  let subtitle = "";
  switch (programType) {
    case ProgramType.MICROMASTERS:
      subtitle = `${courseCount} guaduate-level courses`;
      break;
    case ProgramType.PROFESSIONALCERTIFICATE:
      subtitle = `${courseCount} skill-building courses`;
      break;
    default:
      subtitle = `${courseCount} high-quality courses`;
  }
  return (
    <IconDescription
      iconType="program"
      title="Expert instruction"
      subtitle={subtitle}
    />
  );
}
