import CertificateImage from "../../../CertificateImage";
import CertificateInfo, {
  CertificateInfoProps,
} from "../../../CertificateInfo";
import { defaultTabCertificateInfoData } from "../../../constants/certitificateInfo";

export default function CertificateInfoTab({
  externalData,
}: {
  externalData?: CertificateInfoProps;
}) {
  return (
    <>
      <CertificateInfo
        title={externalData?.title ?? defaultTabCertificateInfoData.title}
        details={externalData?.details ?? defaultTabCertificateInfoData.details}
      />
      <CertificateImage />
    </>
  );
}
