import { PacingType } from "@/lib/program/types";
import IconDescription from "@/components/IconDescription";

export function getProgramPacingTypeIcon(pacingType: string) {
  switch (pacingType) {
    case PacingType.SELFPACED:
      return (
        <IconDescription
          iconType="person"
          title="Self-paced"
          subtitle="Progress at your own speed"
        />
      );

    case PacingType.INSTRUCTORPACED:
      return (
        <IconDescription
          iconType="person"
          title="Instructor-led"
          subtitle="Assignemnts and exams have specific due dates"
        />
      );
  }
  return null;
}
