"use client";
import { Enrollment } from "@/lib/partner-api/getEnrollments";
import { Entitlement } from "@/lib/partner-api/getEntitlements";
import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
} from "react";

type State = {
  userEnrollments: Enrollment[] | null;
  userEntitlements: Entitlement[] | null;
  isUserEnrolled?: boolean;
  isUserEntitled?: boolean;
  emailOptIn: boolean;
};

type Action =
  | { type: "SET_USER_ENROLLMENTS"; payload: Enrollment[] }
  | { type: "SET_IS_USER_ENROLLED"; payload: boolean }
  | { type: "SET_USER_ENTITLEMENTS"; payload: Entitlement[] }
  | { type: "SET_IS_USER_ENTITLED"; payload: boolean }
  | { type: "SET_EMAIL_OPT_IN"; payload: boolean };

const CourseContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: { userEnrollments: [], userEntitlements: [], emailOptIn: false },
  dispatch: () => null,
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_USER_ENROLLMENTS":
      return { ...state, userEnrollments: action.payload };
    case "SET_IS_USER_ENROLLED":
      return { ...state, isUserEnrolled: action.payload };
    case "SET_USER_ENTITLEMENTS":
      return { ...state, userEntitlements: action.payload };
    case "SET_IS_USER_ENTITLED":
      return { ...state, isUserEntitled: action.payload };
    case "SET_EMAIL_OPT_IN":
      return { ...state, emailOptIn: action.payload };
    default:
      return state;
  }
};

type CourseContextProviderProps = Partial<State> & {
  children: ReactNode;
};

export const CourseContextProvider = ({
  children,
  userEnrollments,
  userEntitlements,
  isUserEnrolled,
  isUserEntitled,
  emailOptIn,
}: CourseContextProviderProps) => {
  const initialState: State = {
    userEnrollments: userEnrollments || null,
    userEntitlements: userEntitlements || null,
    isUserEnrolled: isUserEnrolled || false,
    isUserEntitled: isUserEntitled || false,
    emailOptIn: emailOptIn || false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CourseContext.Provider value={{ state, dispatch }}>
      {children}
    </CourseContext.Provider>
  );
};

export const useCourseContext = () => useContext(CourseContext);
