import ExpandableBlock from "@/components/ContentBlock/variants/ExpandableBlock";
import getFormattedDateRange from "@/lib/course/utils/getFormattedDateRange";
import parse from "html-react-parser";

export interface CurriculumProps {
  syllabus: string;
  weeksToComplete?: number | null;
  minEffort?: number | null;
  maxEffort?: number | null;
  formattedLanguage?: string | null;
  formattedTranscripts?: string[];
}

export default function Curriculum({
  syllabus,
  weeksToComplete,
  minEffort,
  maxEffort,
  formattedLanguage,
  formattedTranscripts = [],
}: CurriculumProps) {
  const totalTime = weeksToComplete ? `${weeksToComplete} Weeks` : null;
  const effortRange = getFormattedDateRange(minEffort, maxEffort);

  return (
    <div className="flex flex-col gap-6">
      <h2 className="m-0">Curriculum</h2>
      <div className="border border-putty-400 rounded-xl">
        <div className="flex flex-col align-items-center gap-4 py-6 px-4 sm:px-10 bg-putty-400 rounded-t-xl">
          {(totalTime || effortRange) && (
            <h3 className="m-0 text-gray-800">
              {[totalTime, effortRange].filter(Boolean).join(", ")}
            </h3>
          )}
          <div className="flex flex-col sm:flex-row gap-4 flex-wrap">
            {formattedLanguage && (
              <div className="flex flex-row gap-4">
                <p className="text-primary sm:text-gray-800 font-bold m-0 text-sm sm:text-base">
                  Language
                </p>
                <p className="m-0 text-sm sm:text-base">{formattedLanguage}</p>
              </div>
            )}
            {formattedLanguage && formattedTranscripts?.length > 0 ? (
              <p className="m-0 hidden sm:block">|</p>
            ) : null}
            {formattedTranscripts?.length > 0 ? (
              <div className="flex flex-row gap-x-4 flex-wrap">
                <p className="text-primary sm:text-gray-800 font-bold m-0 text-sm sm:text-base">
                  Transcripts
                </p>
                {formattedTranscripts.map((transcript, index) => (
                  <p className="m-0 text-sm sm:text-base" key={index}>
                    {transcript}
                  </p>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div className="py-8 bg-white rounded-b-xl px-4 sm:px-10">
          <ExpandableBlock
            maxHeight={250}
            heading=""
            btnClassName="text-primary sm:text-gray-800 text-[18px] font-medium mt-4 no-underline sm:underline sm:underline-offset-4"
          >
            <div
              className="prose [&_*]:text-gray-800 [&>*:last-child]:m-0 [&>ul>li:last-child>*:last-child]:m-0"
              style={{ wordBreak: "keep-all" }}
            >
              {parse(syllabus)}
            </div>
          </ExpandableBlock>
        </div>
      </div>
    </div>
  );
}
