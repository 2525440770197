import triggerConversionEvent from "./triggerConversionEvent";
import { createEnrollment } from "@/lib/partner-api/createEnrollment";
import getTrackSelectionUrl from "./getTrackSelectionUrl";
import { EnrollHandlerProps } from "../types";

const enroll = async ({
  courseRun,
  course,
  type,
  opts,
  isUserEntitled,
  emailOptIn,
  commerceCoodrinatorUrl,
}: EnrollHandlerProps) => {
  try {
    await createEnrollment({
      courseId: courseRun.key,
      emailOptIn,
    });

    triggerConversionEvent({
      course,
      isUserEntitled,
      courseRun,
      customName: type,
    })();
  } catch (error) {
    //TODO: There is specific error handling for 403, where the users get redirected to user_message_url in the error message
    //Otherwise normal redirect applies
  } finally {
    //Redirects to track in all cases
    window.location.href = opts?.directToPayment
      ? commerceCoodrinatorUrl
      : getTrackSelectionUrl(courseRun.key);
  }
};

export default enroll;
