"use client";
import FullSkeleton from "@/components/ui/skeleton/variants/fullPageSkeleton";
import UseSingleCourseDetailPageRedesign from "@/monarch/rules/singleCourseDetailPageRedesign/useSingleCourseDetailPageRedesign";

interface WithMonarchProps {
  Control: React.ReactNode;
  TestComponent: React.ReactNode;
  Loader?: React.ReactNode;
}

export const MonarchResolver = ({
  Control,
  TestComponent,
  Loader,
}: WithMonarchProps) => {
  const { isLoading, showRedesign } = UseSingleCourseDetailPageRedesign();
  if (isLoading && Loader) {
    return Loader;
  }

  if (isLoading) {
    return <FullSkeleton />;
  }

  return showRedesign ? TestComponent : Control;
};
