import { getDateDayMonth, isDateInPast } from "@/lib/date";

const getCourseRunEnrollButtonText = (
  {
    enrollmentStart,
    enrollmentEnd,
    end,
  }: {
    enrollmentStart: Date | null;
    enrollmentEnd: Date | null;
    end: Date | null;
  },
  userEnrolled: boolean,
) => {
  const enrollmentEnded = enrollmentEnd ? isDateInPast(enrollmentEnd) : false;
  const courseRunEnded = end ? isDateInPast(end) : false;
  const enrollmentStarted = !enrollmentStart || isDateInPast(enrollmentStart);

  // view course materials
  if (!userEnrolled && enrollmentEnded) {
    return "Future dates to be announced";
  }

  // either the course run has ended
  if (courseRunEnded || enrollmentEnded) {
    return "View course materials";
  }

  // course run has not ended, user is enrolled
  if (userEnrolled) {
    return "Enrolled: Go to course";
  }

  // course enrollment has not started
  if (!enrollmentStarted) {
    return `Enroll after ${getDateDayMonth(new Date(enrollmentStart))}`;
  }

  // course is enrollable and user is not already enrolled
  return "Enroll";
};

export const getEnrollButtonText = (
  {
    enrollmentStart,
    enrollmentEnd,
    end,
  }: {
    enrollmentStart: Date | null;
    enrollmentEnd: Date | null;
    end: Date | null;
  },
  userEnrolled: boolean,
) => {
  const enrollmentEnded = enrollmentEnd ? isDateInPast(enrollmentEnd) : false;
  const courseRunEnded = end ? isDateInPast(end) : false;
  const enrollmentStarted = !enrollmentStart || isDateInPast(enrollmentStart);

  if (courseRunEnded || enrollmentEnded) {
    return "View course materials";
  }

  // course run has not ended, user is enrolled
  if (userEnrolled) {
    return "Enrolled: Go to course";
  }

  // course enrollment has not started
  if (!enrollmentStarted) {
    return `Enroll after ${getDateDayMonth(new Date(enrollmentStart))}`;
  }
  // course is enrollable and user is not already enrolled
  return "Earn a certificate";
};

export default getCourseRunEnrollButtonText;
