import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/workspace/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/app/[locale]/learn/[category]/[courseSlug]/components/NewOpenCourseContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/app/[locale]/learn/[category]/[courseSlug]/components/OpenCourseContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/AdminToolbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MonarchResolver"] */ "/workspace/src/components/CourseContent/CourseContentPageWrapper/MonarchResolver.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CourseContextProvider"] */ "/workspace/src/components/CourseContent/CourseContext/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Footer/SecondaryLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Navigation/index.tsx");
