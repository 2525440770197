import { CertificateStatsProps } from "../CertificateStats";

export const defaultCertificateStatsData: CertificateStatsProps = {
  stats: [
    {
      percentageNumber: 84,
      message:
        "of edX learners have seen positive professional growth after earning a certificate.",
      source: "edX certificate satisfaction survey results",
    },
  ],
};

export const defaultTabCertificateStatsData: CertificateStatsProps = {
  title: "See real results",
  stats: [
    {
      percentageNumber: 84,
      message:
        "of edX learners have seen positive professional growth after earning a certificate.",
      source: "edX certificate satisfaction survey results",
    },
    {
      percentageNumber: 57,
      message:
        "of edX learners felt that earning a certificate strengthened their resume.",
      source: "edX certificate satisfaction survey results",
    },
  ],
};
