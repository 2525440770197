import React from "react";
import Icon from "@/components/icons";
import { PathInfo as PathInfoProps } from "@/components/CourseContent/ContentRefresh/Certificate/CertificateEmphasisTabs/components/PathInfoTab/types";
import useCertificateEmphasisRedesign from "@/monarch/rules/certificateEmphasisRedesign/useCertificateEmphasisRedesign";

export default function PathInfo({
  title,
  auditInfo,
  certificateInfo,
  list,
}: PathInfoProps) {
  const { pathInfo } = useCertificateEmphasisRedesign();
  const effectiveTitle = pathInfo?.title || title;
  const effectiveAuditInfo = pathInfo?.auditInfo || auditInfo;
  const effectiveCertificateInfo = pathInfo?.certificateInfo || certificateInfo;
  const effectiveList = pathInfo?.list || list;
  return (
    <div className="flex flex-col flex-1 gap-6">
      <h2 className="my-0 text-putty-300 italic text-3xl md:text-4xl tracking-[-0.06rem]">
        {effectiveTitle}
      </h2>
      <p className="text-lg text-white">
        <strong>{effectiveAuditInfo.header}: </strong>
        {effectiveAuditInfo.info}
      </p>
      <p className="text-lg text-white">
        <strong>{effectiveCertificateInfo.header}: </strong>
        {effectiveCertificateInfo.info}
      </p>
      {effectiveList && (
        <ul className="flex flex-col gap-4 text-white">
          {list?.map((item) => (
            <li key={item.heading} className="flex items-start gap-4">
              <div className="w-5 h-6 translate-y-1">
                <Icon
                  type="circle-check-outline"
                  svgProps={{ width: "20", height: "20" }}
                  pathProps={{ fill: "white" }}
                />
              </div>
              <div className="text-base">
                <b>{item.heading}</b>
                {item.description}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
