import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import Icon from "@/components/icons";
import { Button } from "@/components/ui/button";
import { CourseRun } from "@/lib/courseRun/types";
import { ProcessedCourse } from "@/lib/course/types";
import { DEFAULT_USER_CURRENCY } from "@/lib/course/utils/constants";
import getCoursePrice from "@/lib/course/utils/getCoursePrice";
import getCourseRunExpirationDateString from "@/lib/course/utils/getCourseRunExpirationDateString";
import { formatPrice } from "@/utils/functions/formatting";
import EnrollLink from "../CourseEnroll/CourseEnrollButtons/EnrollLink";
import AuditLink from "../CourseEnroll/CourseEnrollButtons/AuditLink";
import { getEnrollButtonText } from "@/lib/course/utils/getCourseRunEnrollButtonText";
import useCourseEnrollment from "../CourseEnroll/hooks/useCourseEnrollment";

const UNLIMITED_ACCESS_TOOLTIP = `You will have unlimited access to the course material.
                After the course ends, you will still have access to the material, but you will no longer be able to submit graded assignments or earn a certificate.`;

const LIMITED_ACCESS_TOOLTIP =
  "In the free audit track you may not be able to access and complete course materials including, but not limited to, graded assignments and exams.";

const LIST_ITEMS = [
  "World class institutions",
  "edX support",
  "Graded assessments",
  "Earn a certificate",
];

function getInfoToolTip(text: string) {
  return (
    <Popover>
      <PopoverTrigger className="ml-2 relative top-1">
        <Icon type="info" svgProps={{ width: "20px", height: "20px" }} />
      </PopoverTrigger>
      <PopoverContent className="bg-primary p-4 rounded-xl max-w-56 mx-auto text-white text-sm">
        {text}
      </PopoverContent>
    </Popover>
  );
}

export interface NewWaysToTakeCourse {
  activeCourseRun: CourseRun;
  course: ProcessedCourse;
}

export default function WaysToTakeCourse({
  activeCourseRun,
  course,
}: NewWaysToTakeCourse) {
  const { isUserEnrolledInRun } =
    useCourseEnrollment({
      courseRun: activeCourseRun,
      course,
    }) || {};

  const expirationDate = getCourseRunExpirationDateString(activeCourseRun);
  const showAuditModeExpirationDate = true; //TO DO: Get from context, look into ShowAuditModeExpirationDateContext in prospectus
  const coursePrice = getCoursePrice(activeCourseRun);

  const enrollButtonText = getEnrollButtonText(
    {
      enrollmentStart: activeCourseRun.enrollmentStart,
      enrollmentEnd: activeCourseRun.enrollmentEnd,
      end: activeCourseRun.end,
    },
    isUserEnrolledInRun || false,
  );

  return (
    <div className="flex flex-col gap-6">
      <h2 className="m-0">Ways to take this course</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="border border-putty-400 rounded-xl">
          <div className="flex justify-between items-end bg-primary-500 p-6 md:py-6 md:px-10 rounded-t-xl">
            <div className="flex items-center space-x-2">
              <h3 className="text-white text-[16px] sm:text-lg m-0">
                Certificate
              </h3>
              <div className="border border-[#A5B5B1] rounded text-white px-2 py-1 text-xs">
                Premium
              </div>
            </div>
            {coursePrice && (
              <h3 className="text-white text-[16px] sm:text-lg m-0">
                {formatPrice(coursePrice, DEFAULT_USER_CURRENCY)}
              </h3>
            )}
          </div>
          <div className="flex flex-col py-10 px-6 sm:px-10 bg-white rounded-b-xl h-auto md:h-[396px] gap-8 justify-between">
            <ul className="flex flex-col gap-6 text-gray-800">
              <li>
                Unlimited Access {getInfoToolTip(UNLIMITED_ACCESS_TOOLTIP)}
              </li>
              {LIST_ITEMS.map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
            <EnrollLink courseRun={activeCourseRun} course={course} />
          </div>
        </div>
        <div className="border border-putty-400 rounded-xl">
          <div className="flex p-6 md:py-6 md:px-10 bg-putty-400 rounded-t-xl">
            <h3 className="text-primary-500 text-[16px] sm:text-lg m-0">
              Audit
            </h3>
          </div>
          <div className="flex flex-col py-10 px-6 sm:px-10 bg-white rounded-b-xl h-auto md:h-[396px] gap-8 justify-between">
            <ul className="flex flex-col gap-6 text-gray-800">
              {showAuditModeExpirationDate && expirationDate && (
                <li>
                  {`Access Expires ${expirationDate}`}{" "}
                  {getInfoToolTip(LIMITED_ACCESS_TOOLTIP)}
                </li>
              )}
              {LIST_ITEMS.slice(0, 2).map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
            <AuditLink courseRun={activeCourseRun} course={course} />
          </div>
        </div>
      </div>
    </div>
  );
}
