import { PathInfo as PathInfoProps } from "@/components/CourseContent/ContentRefresh/Certificate/CertificateEmphasisTabs/components/PathInfoTab/types";

export const defaultPathInfoData: PathInfoProps = {
  title: "Choose your path",
  auditInfo: {
    header: "Audit",
    info: "You may complete this course at no cost, but you will not receive a certificate and will only have access to this course for a limited time.",
  },
  certificateInfo: {
    header: "Certificate",
    info: "Get unlimited access to all course materials, graded assignments, and a certificate to share on LinkedIn or with your employer to prove you're ready for the next step in your career.",
  },
};
