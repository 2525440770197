"use client";
import useUserEnrollments from "@/components/CourseContent/CourseContext/hooks/useUserEnrollments";
import useUserEntitlements from "@/components/CourseContent/CourseContext/hooks/useUserEntitlements";
import useIsMobile from "@/hooks/useIsMobile";
import isUserLoggedIn from "@/lib/auth/isUserLoggedIn";
import isUserEnrolled from "@/lib/course/utils/isUserEnrolled";
import { useSearchParams } from "next/navigation";
import enrollmentHandler from "../utils/enrollmentHandler";
import { EnrollCourseHookProps } from "../types";
import getEnrollmentClickEventPayload from "../utils/getEnrollmentClickEventPayload";
import useLeadWithPaid from "@/monarch/rules/leadWithPaid/useLeadWithPaid";
import triggerConversionEventUtil from "../utils/triggerConversionEvent";
import { isDateInPast } from "@/lib/date";
import useEmailOptIn from "@/components/CourseContent/CourseContext/hooks/useEmailOptIn";

function useCourseEnrollment({ courseRun, course }: EnrollCourseHookProps) {
  const { userEnrollments } = useUserEnrollments();
  const { isUserEntitled } = useUserEntitlements();
  const { emailOptIn } = useEmailOptIn();
  const isMobile = useIsMobile();
  const searchParams = useSearchParams();
  const isLoggedIn = isUserLoggedIn();
  const { value: paidLeadValues, isLoading: isPaidLeadLoading } =
    useLeadWithPaid(course);

  const { conversionType, linkDirectToPaymentPage } = paidLeadValues ?? {};

  const isUserEnrolledInRun = isUserEnrolled(
    userEnrollments || [],
    courseRun.key,
  );

  const hasSearchParams = searchParams.toString().length > 0;

  let isFromSearchPage = false;
  let isFromCoursePage = false;

  if (typeof document !== "undefined" && document.referrer) {
    const referrerUrl = document.referrer;
    isFromSearchPage = referrerUrl.includes("/search");
    isFromCoursePage = referrerUrl.includes("/courses");
  }

  const shouldHaveQueryParams =
    (isFromSearchPage && hasSearchParams) || (isFromCoursePage && isLoggedIn);

  const skus = [
    courseRun.seats?.find((seat) => seat.type === "verified")?.sku,
    courseRun.seats?.find((seat) => seat.type === "professional")?.sku,
  ];

  const commerceCoodrinatorUrl = `https://commerce-coordinator.edx.org/lms/payment_page_redirect/?sku=${skus[0] ?? skus[1]}&course_run_key=${courseRun.key}`;

  const { isEnrollable: isCourseEnrollable } = courseRun;

  const isCourseArchived = courseRun.end ? isDateInPast(courseRun.end) : false;

  const handleEnrollment = async (type?: string) => {
    enrollmentHandler({
      courseRun,
      course,
      isUserEntitled,
      emailOptIn,
      type: type || conversionType,
      opts: {
        directToPayment: Boolean(linkDirectToPaymentPage),
      },
      commerceCoodrinatorUrl,
      shouldHaveQueryParams,
      isUserEnrolledInRun,
      isLoggedIn,
      isMobile,
      searchParams,
    })();
  };

  const getEnrollmentClickEventData = () => {
    return getEnrollmentClickEventPayload({
      courseRun,
      emailOptIn,
      course,
      shouldHaveQueryParams,
      isUserEnrolledInRun,
      isLoggedIn,
      isUserEntitled,
      isMobile,
      searchParams,
    });
  };

  const triggerConversionEvent = () => {
    triggerConversionEventUtil({
      customName: conversionType,
      courseRun,
      isUserEntitled,
      course,
    })();
  };

  return {
    getEnrollmentClickEventData,
    handleEnrollment,
    isUserEnrolledInRun,
    isCourseEnrollable,
    isLoggedIn,
    commerceCoodrinatorUrl,
    triggerConversionEvent,
    paidLeadValues,
    isPaidLeadLoading,
    isCourseArchived,
    emailOptIn,
  };
}

export default useCourseEnrollment;
