import { Award } from "@/types/Customizations";

function getAwardSize(award: Award): { width: number; height: number } {
  if (award.orientation?.toLowerCase() === "landscape") {
    return { width: 290, height: 80 };
  } else if (award.orientation?.toLowerCase() === "square") {
    return { width: 120, height: 120 };
  } else {
    return { width: 100, height: 120 };
  }
}

export default getAwardSize;
