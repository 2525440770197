import { CTAProps } from "../../types";

export default function MultiCourse({ title, subtitle, children }: CTAProps) {
  return (
    <div className="bg-putty-200 lg:px-auto lg:py-20 fullwidth">
      <div className="flex flex-col lg:flex-row pb-8">
        <h2 className="elm-d2 italic basis-1/2 font-black mb-4 mr-6 mt-0">
          {title}
        </h2>
        <p className="basis-1/2 mt-2 mb-4">{subtitle}</p>
      </div>
      {children}
    </div>
  );
}
