import Image from "next/image";
import Link from "next/link";

const ACERecommendationCard = ({ aceLink }: { aceLink: string }) => {
  return (
    <div>
      <h2 className="text-xl font-bold mb-4">ACE recommended</h2>

      <div className="bg-putty-400 border border-gray-200 rounded-xl pt-10 pb-6 px-4 sm:pt-10 sm:pb-6 sm:pl-10 sm:pr-6 space-y-6">
        <div className="flex flex-col lg:flex-row items-start lg:items-center gap-6">
          <div className="bg-white border rounded-[8px] p-4 flex-shrink-0 lg:mx-auto lg:mx-0">
            <Image
              src="/images/ACELogo.svg"
              alt="ACE Logo"
              width={225}
              height={111}
              objectFit="contain"
              className="mx-auto max-w-none w-[225px] h-[111px]"
            />
          </div>

          <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-6 flex-grow">
            <p className="text-gray-800 flex-grow">
              <strong>
                This course has been recommended for college credit by ACE
                Learning Evaluations.
              </strong>{" "}
              ACE helps learners gain academic credit for courses and
              examinations taken outside traditional degree programs.
            </p>

            <div className="w-full lg:w-auto flex-shrink-0">
              <Link
                href="/credit"
                className="bg-primary-500 hover:bg-primary-300 text-white text-center px-5 py-2 rounded-full w-full sm:w-auto block"
              >
                See credit recommendation
              </Link>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between lg:items-end gap-6">
          <p className="text-sm text-gray-800 md:text-left mb-0">
            Note: The decision to accept specific credit recommendations is up
            to each institution.
          </p>

          <a
            href={aceLink}
            className="text-base text-gray-800 underline hover:text-gray-900 md:text-left"
          >
            Learn more about ACE
          </a>
        </div>
      </div>
    </div>
  );
};

export default ACERecommendationCard;
