import { CourseRun } from "@/lib/courseRun/types";

const getCourseRunStartDate = ({
  // isEnrollable, pacingType, start, end,
  pacingType,
  start,
  end,
}: CourseRun) => {
  const isEnrollable = true; // TODO: Remove once isEnrollable is avaialble
  // enrollable, not yet started
  if (start && isEnrollable && new Date(start) > new Date()) {
    // all courses with start dates within the next calendar year drop the year
    return start;
  }

  // enrollable, started, not ended
  if (isEnrollable && (!end || new Date(end) > new Date())) {
    // self-paced
    if (pacingType === "self_paced") {
      return new Date();
    }
    // instructor-paced
    return start;
  }
};

export default getCourseRunStartDate;
