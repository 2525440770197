import { enrollButtonClickEvent } from "@/lib/segment";
import enroll from "./enroll";
import getEnrollmentClickEventPayload from "./getEnrollmentClickEventPayload";
import { EnrollHandlerProps } from "../types";

const enrollmentHandler =
  ({
    courseRun,
    course,
    isUserEntitled,
    emailOptIn,
    type,
    opts = { directToPayment: false },
    commerceCoodrinatorUrl,
    shouldHaveQueryParams,
    isUserEnrolledInRun,
    isLoggedIn,
    isMobile,
    searchParams,
  }: EnrollHandlerProps) =>
  async () => {
    enrollButtonClickEvent(
      getEnrollmentClickEventPayload({
        courseRun,
        course,
        isUserEntitled,
        emailOptIn,
        shouldHaveQueryParams,
        isUserEnrolledInRun,
        isLoggedIn,
        isMobile,
        searchParams,
      }),
    );
    await enroll({
      courseRun,
      course,
      isUserEntitled,
      emailOptIn,
      type,
      opts,
      commerceCoodrinatorUrl,
      shouldHaveQueryParams,
      isUserEnrolledInRun,
      isLoggedIn,
      isMobile,
      searchParams,
    });
  };

export default enrollmentHandler;
