import { defaultPathInfoData } from "../../../constants/pathInfo";
import PathInfo from "../../../PathInfo";
import WaysToTakeCourseTable from "../../../CertificateWaysToTakeCourse/WaysToTakeCourseTable";
import { PathInfo as PathInfoProps } from "./types";

export default function PathInfoTab({
  externalData,
  coursePrice,
}: {
  externalData?: PathInfoProps;
  coursePrice: number;
}) {
  return (
    <>
      <PathInfo
        title={externalData?.title ?? defaultPathInfoData.title}
        auditInfo={externalData?.auditInfo ?? defaultPathInfoData.auditInfo}
        certificateInfo={
          externalData?.certificateInfo ?? defaultPathInfoData.certificateInfo
        }
        list={externalData?.list ?? defaultPathInfoData.list}
      />
      <WaysToTakeCourseTable coursePrice={coursePrice} />
    </>
  );
}
