import ContentBlock from "@/components/ContentBlock";
import ExpandableBlock from "@/components/ContentBlock/variants/ExpandableBlock";
import parse from "html-react-parser";
export interface AuthorStatementTestimonial {
  textRaw: string;
  author: string;
}
export interface LearnerTestimonialsProps {
  testimonials: string | AuthorStatementTestimonial[];
}

export default function LearnerTestimonials({
  testimonials,
}: LearnerTestimonialsProps) {
  function styleTestimonials(html: string) {
    const paragraphs = html.match(/<p\b[^>]*>(.*?)<\/p>/g);
    if (paragraphs) {
      const lastParagraph = paragraphs[paragraphs.length - 1];
      const modifiedLastParagraph = lastParagraph.replace(
        "<p",
        '<p class="mb-0"',
      );
      html = html.replace(lastParagraph, modifiedLastParagraph);
    }

    return html
      .replace(/<ul>/g, '<ul class="list-disc pl-4 text-base">')
      .replace(/<ol>/g, '<ol class="list-decimal pl-4 text-base">')
      .replace(/<li>/g, '<li class="ml-4 list-outside">')
      .replace(/<a /g, '<a class="text-info-500 hover:underline" ');
  }

  return (
    <div>
      <h2 className="elm-h3 mt-0">Learner Testimonials</h2>
      <div className="gap-6 flex flex-col">
        {typeof testimonials === "string" && (
          <ContentBlock variant="quoted" imageurl={""} imagetitle={""}>
            <div className="md:text-lg mb-0" style={{ lineHeight: "1.925rem" }}>
              <ExpandableBlock
                maxHeight={180}
                btnClassName="text-info-500 text-[16px] font-normal mt-4 no-underline"
              >
                {parse(styleTestimonials(testimonials))}
              </ExpandableBlock>
            </div>
          </ContentBlock>
        )}

        {Array.isArray(testimonials) &&
          testimonials.map((testimonial, index) => (
            <ContentBlock
              key={index}
              variant="quoted"
              imageurl={""}
              imagetitle={""}
            >
              <div>
                <p className="md:text-lg" style={{ lineHeight: "1.925rem" }}>
                  <ExpandableBlock
                    maxHeight={90}
                    showMoreLabel="More"
                    btnClassName="text-info-500 text-[16px] font-normal mt-2 no-underline"
                  >
                    {parse(testimonial.textRaw)}
                  </ExpandableBlock>
                </p>
                <p className="font-bold md:text-lg mb-0">
                  {testimonial.author}
                </p>
              </div>
            </ContentBlock>
          ))}
      </div>
    </div>
  );
}
