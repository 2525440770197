import Expand from "@/components/CourseContent/Expand";
import AccordionContent from "@/components/AccordionContent";
import parse from "html-react-parser";
import { cn } from "@/lib/utils";

type Props = {
  link?: string;
  title?: string;
  titleMobile?: string;
  titleClassName?: string;
  parseContent?: string;
  children?: React.ReactNode;
  mobileChildren?: React.ReactNode;
  showSectionTitle?: boolean;
  topComponent?: React.ReactNode;
  bottomComponent?: React.ReactNode;
  bottomFullWidthMobile?: boolean;
  className?: string;
};
export default function Section({
  link,
  title,
  titleMobile,
  titleClassName,
  parseContent,
  children,
  mobileChildren,
  showSectionTitle = true,
  topComponent,
  bottomComponent,
  bottomFullWidthMobile = false,
  className = "",
}: Props) {
  return (
    <div id={link} className="scroll-mt-[5rem]">
      <div className={cn(`hidden md:block relative`, className)}>
        <div className="absolute -top-24" />
        {title && showSectionTitle && (
          <h2
            className={cn(
              "pt-6 mt-4 text-primary mb-2 text-3xl font-bold",
              titleClassName,
            )}
          >
            {title}
          </h2>
        )}
        {parseContent && (
          <>
            {topComponent && <div className="py-2">{topComponent}</div>}
            <Expand>
              <div className="prose" style={{ wordBreak: "keep-all" }}>
                {parse(parseContent)}
              </div>
            </Expand>
            {bottomComponent && <div className="pt-2">{bottomComponent}</div>}
          </>
        )}
        {children}
      </div>
      <div className="flex md:hidden flex-col">
        <AccordionContent title={titleMobile || title || ""}>
          <>
            <div className="px-4">
              {topComponent && <div className="py-2">{topComponent}</div>}
              {parseContent && (
                <div className="prose" style={{ wordBreak: "keep-all" }}>
                  {parse(parseContent)}
                </div>
              )}
              {mobileChildren || children}
            </div>
            {bottomComponent && (
              <div
                className={`pt-4 ${bottomFullWidthMobile ? "px-0" : "px-4"}`}
              >
                {bottomComponent}
              </div>
            )}
          </>
        </AccordionContent>
      </div>
    </div>
  );
}
