import {
  SingleCourseDetailPageRedesignProps,
  UseSingleCourseDetailPageRedesignProps,
} from "./types";
import { useMonarchRule } from "../MonarchRulesProvider";

const CUSTOM_RULE_ID = "singleCourseDetailPageRedesign";

const UseSingleCourseDetailPageRedesign: UseSingleCourseDetailPageRedesignProps =
  () => {
    const { value, isLoading } = useMonarchRule<{
      data: SingleCourseDetailPageRedesignProps;
      variantCustomId: string;
    }>(CUSTOM_RULE_ID, {});

    return {
      showRedesign: value?.data?.showRedesign,
      isLoading,
      headerBackground: value?.data?.headerBackground,
    };
  };

export default UseSingleCourseDetailPageRedesign;
