import Image from "next/image";
import Link from "next/link";
import parse from "html-react-parser";
import { toggleEmailOptIn as trackToggleEmailOptIn } from "@/lib/segment";

import EmailOptIn from "@/components/EmailOptIn/EmailOptIn";
import ExpandableBlock from "@/components/ContentBlock/variants/ExpandableBlock";

import { PartialPartner } from "@/lib/partner/types";
import Icon from "@/components/icons";
import useUserEnrollments from "../../CourseContext/hooks/useUserEnrollments";
import CourseRating from "../../CourseRating";
import { ProcessedCourse } from "@/lib/course/types";
import CourseRunSelector from "../CourseRunSelector";
import { hideEmailOptInForCourse } from "../../CourseRunSelector/constants";
import isCourseArchived from "@/lib/course/utils/isCourseArchived";
import useEmailOptIn from "../../CourseContext/hooks/useEmailOptIn";
import { partnerImageDimensions } from "@/constants/hero/imageDimensions";

export interface ICoursePageHeroProps {
  title: string;
  aceLink?: string | null;
  course: ProcessedCourse;
}

const MIN_ENROLLMENT_COUNT_FOR_DISPLAY = 5000;

export default function NewCourseHeader({
  title,
  aceLink = "",
  course,
}: ICoursePageHeroProps) {
  const { emailOptIn, setEmailOptIn } = useEmailOptIn();
  const { isUserEnrolled } = useUserEnrollments();
  const { activeCourseRun } = course;
  const partners = course.partners.length ? course.partners : [];
  const isArchived = activeCourseRun ? isCourseArchived(activeCourseRun) : true;
  const description = course.productShortDescription;
  const id = course.productUuid;
  const productName = course.productName;
  const courseReview = course.ratings;
  const enrollmentCount = course.enrollmentCount;
  const subTitle = `Course from ${partners.at(0)?.name} (${partners.at(0)?.key})`;
  const showEnrollmentCount =
    !isArchived &&
    activeCourseRun?.isEnrollable &&
    enrollmentCount &&
    enrollmentCount >= MIN_ENROLLMENT_COUNT_FOR_DISPLAY;

  const toggleEmailOptIn = () => {
    const optIn = !emailOptIn;
    setEmailOptIn(optIn);
    if (activeCourseRun?.key) {
      trackToggleEmailOptIn({
        courseRunKey: activeCourseRun?.key,
        optIn: optIn,
      });
    }
  };

  const isRetiringSoon = course.customizations?.catalogPage?.retiringSoon;
  const retiringSoonText =
    course.customizations?.catalogPage?.retiringSoonTextOverwrite ||
    "This course is retiring soon";

  return (
    <div className="bg-white rounded-xl py-6 px-4 sm:p-10 md:flex md:items-start md:justify-between">
      <div className="flex flex-col gap-5 w-full">
        <div>
          <div className="flex justify-center sm:justify-start">
            {partners.map((partner: PartialPartner) => {
              return partner.showOnSchoolsAndPartnersPage ? (
                <Link
                  className="d-inline-block"
                  key={partner.key}
                  href={`/school/${partner.slug}`}
                >
                  <Image
                    src={partner.logoUrl || ""}
                    width={partnerImageDimensions.width}
                    height={partnerImageDimensions.height}
                    alt={title || ""}
                    title={title || ""}
                    key={partner.key}
                    className="max-w-[165px] md:max-w-full"
                  />
                </Link>
              ) : (
                <Image
                  src={partner.logoUrl || ""}
                  width={partnerImageDimensions.width}
                  height={partnerImageDimensions.height}
                  alt={title || ""}
                  title={title || ""}
                  key={partner.key}
                  className="max-w-[165px] md:max-w-full"
                />
              );
            })}
          </div>
          <p className="mb-5 md:mb-4 text-sm md:text-base">{subTitle}</p>
          <h1 className="m-0 p-0 tracking-tight" style={{ fontWeight: 900 }}>
            {title}
          </h1>
        </div>
        {courseReview && <CourseRating {...courseReview} variant="solid" />}
        <div className="md:[&_p]:text-lg [&_p]:m-0 hidden md:block">
          {parse(description)}
        </div>
        <div className="[&_p]:m-0 block md:hidden">
          <ExpandableBlock
            maxHeight={90}
            heading=""
            showMoreLabel="Read more"
            btnClassName="text-gray-800 mt-1"
          >
            <div className="prose" style={{ wordBreak: "keep-all" }}>
              {parse(description)}
            </div>
          </ExpandableBlock>
        </div>
        {aceLink && (
          <Link href="#ace" className="block sm:hidden">
            <Image
              alt="ace-logo"
              src="/images/ACELogo.svg"
              width={120}
              height={27}
            />
          </Link>
        )}
        {isRetiringSoon && (
          <div className="flex md:justify-end gap-2 text-primary">
            <Icon type="warning" />
            <p className="m-0 text-black">{retiringSoonText}</p>
          </div>
        )}
        <div className="flex align-items-center xl:justify-end w-full">
          <div
            className={`flex gap-4 flex-col w-full md:w-auto ${course.activeCourseRuns && course.activeCourseRuns.length > 1 ? "xl:w-full" : "md:w-auto lg:flex-row"}`}
          >
            {showEnrollmentCount ? (
              <div className="flex items-center text-base">
                <span className="font-bold m-0">
                  {enrollmentCount.toLocaleString()}
                </span>
                <span className="m-0">&nbsp;learners enrolled</span>
              </div>
            ) : null}
            <CourseRunSelector course={course} />
          </div>
        </div>
        <div className="hidden md:flex md:flex-col">
          {!hideEmailOptInForCourse.includes(id) &&
            !isUserEnrolled &&
            !isArchived &&
            activeCourseRun?.isEnrollable && (
              <EmailOptIn
                optIn={emailOptIn}
                partners={partners}
                title={productName}
                toggleEmailOptIn={toggleEmailOptIn}
                variant="secondary"
              />
            )}
        </div>
      </div>
    </div>
  );
}
