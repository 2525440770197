"use client";
import { isDateInPast } from "@/lib/date";
import { Card } from "@/components/ui/card";
import isCourseArchived from "@/lib/course/utils/isCourseArchived";
import {
  PACING_TYPE_INSTRUCTOR,
  PACING_TYPE_SELF,
  PAYMENT_FREE,
  PAYMENT_UPGRADABLE,
} from "@/lib/course/utils/constants";
import { ReactNode, useState } from "react";
import {
  hideEmailOptInForCourse,
  MAX_DISPLAYED_COURSES,
  MIN_ENROLLMENT_COUNT_FOR_DISPLAY,
} from "./constants";
import EmailOptIn from "@/components/EmailOptIn/EmailOptIn";
import { CourseRun } from "@/lib/courseRun/types";
import { PartialPartner } from "@/lib/partner/types";
import CourseEnrollButton from "../EnrollButton";
import useUserEnrollments from "../CourseContext/hooks/useUserEnrollments";
import getCourseRunTilePrimaryText from "@/lib/course/utils/getCourseRunTilePrimaryText";
import getCourseRunTileSecondaryText from "@/lib/course/utils/getCourseRunTitleSecondaryText";
import { ProcessedCourse } from "@/lib/course/types";
import Icon from "@/components/icons";
import { toggleEmailOptIn as trackToggleEmailOptIn } from "@/lib/segment";

type Props = {
  id: string;
  activeCourseRun?: CourseRun | null;
  activeCourseRuns?: CourseRun[];
  paymentOption: string;
  partners: PartialPartner[];
  enrollmentCount: number;
  course: ProcessedCourse;
  category?: string;
  courseSlug?: string;
};

const enrollmentCountText = (
  enrollmentCount: number,
  paymentOption: string,
  archivedLink: ReactNode,
) => {
  if (enrollmentCount > MIN_ENROLLMENT_COUNT_FOR_DISPLAY) {
    const isFreeOrUpgradable =
      paymentOption === PAYMENT_FREE || paymentOption === PAYMENT_UPGRADABLE;

    return {
      mobileText: isFreeOrUpgradable ? (
        <>{enrollmentCount.toLocaleString()} already enrolled!</>
      ) : (
        ""
      ),
      desktopText: (
        <>
          {enrollmentCount.toLocaleString()} already enrolled! After a course
          session ends, it will be {archivedLink}.
        </>
      ),
    };
  } else {
    return {
      mobileText: <>After a course session ends, it will be {archivedLink}.</>,
      desktopText: <>After a course session ends, it will be {archivedLink}.</>,
    };
  }
};

export default function CourseRunSelector({
  id,
  activeCourseRun,
  activeCourseRuns,
  paymentOption = "",
  partners,
  enrollmentCount,
  course,
  category,
  courseSlug,
}: Readonly<Props>) {
  const [hideExtraRuns, setHideExtraRuns] = useState(true);
  const [emailOptIn, setEmailOptIn] = useState(false);
  const { isUserEnrolled } = useUserEnrollments();

  const multipleRunsAvailable = (activeCourseRuns || []).length > 1;
  const hasHiddenRuns =
    (activeCourseRuns || []).length > MAX_DISPLAYED_COURSES - 1;
  const numberOfStartDates = activeCourseRuns
    ? Object.keys(activeCourseRuns).length
    : 1;
  //const courseType = course.additionalMetadata.externalCourseMarketingType;
  const execEdType = null;
  const enrollmentClosed =
    numberOfStartDates === 1 && !execEdType && !activeCourseRun?.isEnrollable;
  const isArchived = activeCourseRun && isCourseArchived(activeCourseRun);
  const title = activeCourseRun?.title ?? "";
  const hasInstructorPacedCourses = (activeCourseRuns || []).some(
    ({ pacingType }) => pacingType === PACING_TYPE_INSTRUCTOR,
  );
  const hasSelfPacedCourses = (activeCourseRuns || []).some(
    ({ pacingType }) => pacingType === PACING_TYPE_SELF,
  );
  const tagPacingOnTiles = hasInstructorPacedCourses && hasSelfPacedCourses;

  const toggleEmailOptIn = () => {
    const optedIn = !emailOptIn;
    setEmailOptIn(optedIn);
    if (activeCourseRun?.key) {
      trackToggleEmailOptIn({
        courseRunKey: activeCourseRun?.key,
        optIn: optedIn,
      });
    }
    return optedIn;
  };

  const { isEnrollable: courseIsEnrollable } = activeCourseRun || {};

  const getVisibleRuns = () =>
    hideExtraRuns
      ? (activeCourseRuns || []).slice(0, MAX_DISPLAYED_COURSES)
      : activeCourseRuns;

  const toggleRunVisibility = () => {
    setHideExtraRuns(!hideExtraRuns);
  };

  const courseRunTitle = () => {
    if (isArchived) {
      return "This course is archived";
    } else if (enrollmentClosed) {
      return "Enrollment closed";
    } else if (multipleRunsAvailable) {
      return "Choose your session:";
    } else if (!multipleRunsAvailable) {
      return "There is one session available:";
    } else return "-TODO-";
  };

  const getArchivedOrClosedCourseRunTitle = () => {
    const enrollmentEnded = activeCourseRun?.enrollmentEnd
      ? new Date(activeCourseRun.enrollmentEnd) < new Date()
      : false;
    const enrollmentNotStarted = activeCourseRun?.enrollmentStart
      ? new Date(activeCourseRun.enrollmentStart) > new Date()
      : false;

    const courseRunEnded = activeCourseRun?.end
      ? isDateInPast(activeCourseRun.end)
      : false;

    // either the course run has ended, or the enrollment period has ended and the user
    // is not already enrolled
    if (courseRunEnded) {
      return "This course is archived";
    }

    // course run has not ended, user is enrolled
    if (isUserEnrolled) {
      return null;
    }

    // course enrollment has ended
    if (enrollmentEnded) {
      return "Enrollment closed";
    }
    // course enrollment has not started
    if (enrollmentNotStarted) {
      return "Coming soon";
    }

    return null;
  };

  const archivedLink = (
    <a
      className="text-primary underline hover:text-info active:text-info flex"
      target="_blank"
      rel="noopener noreferrer"
      href="https://support.edx.org/hc/en-us/articles/207201017-What-does-archived-mean"
    >
      archived
      <Icon
        type="launch"
        svgProps={{ width: "18", height: "18", viewBox: "0 0 24 18" }}
      />
    </a>
  );

  // Calculate the grid class based on the array length
  const visibleCourseRuns = getVisibleRuns() || [];
  const columnCount = Math.min(visibleCourseRuns.length, MAX_DISPLAYED_COURSES);
  const courseRunsGridColsClass = `grid-cols-${columnCount}`;

  // Control if at least one course run has secondary text to adjust the height of the cards without secondary text
  let hasSecondaryText: boolean = false;

  const mapPacingToText = {
    self_paced: "Self-paced",
    instructor_paced: "Instructor-paced",
  };

  return (
    <div
      id="enroll"
      className="mx-auto mt-0 sm:mt-6 px-4 bg-[#fbfaf9] md:bg-white"
    >
      <div className="flex flex-col mt-0 mb-0 sm:mt-5 sm:mb-4 sm:mb-1 sm:flex-row sm:wrap sm:-mx-4">
        <div className="sm:px-4 mb-4">
          {activeCourseRun &&
          (isArchived || !activeCourseRun?.isEnrollable) &&
          !activeCourseRun.hidden ? (
            <>
              <h2 className="mt-0 mb-2 text-base">
                {getArchivedOrClosedCourseRunTitle()}
              </h2>
              <div className="flex flex-row pt-2 pb-4">
                <CourseEnrollButton
                  id="course-run-selector-active"
                  course={course}
                  courseRun={activeCourseRun}
                  emailOptIn={emailOptIn}
                  category={category}
                  courseSlug={courseSlug}
                ></CourseEnrollButton>
              </div>
              {courseIsEnrollable && (
                <EmailOptIn
                  optIn={emailOptIn}
                  partners={partners}
                  title={title}
                  toggleEmailOptIn={toggleEmailOptIn}
                />
              )}
            </>
          ) : null}
        </div>
      </div>

      {!isArchived && activeCourseRun?.isEnrollable && (
        <>
          <div className="mt-0 mb-2 text-lg sm:mb-2 font-bold">
            {courseRunTitle()}
          </div>
          {enrollmentClosed || isArchived ? null : (
            <div className="mb-4">
              <p className="!text-sm !text-primary flex sm:hidden whitespace-pre">
                {
                  enrollmentCountText(
                    enrollmentCount,
                    paymentOption,
                    archivedLink,
                  )?.mobileText
                }
              </p>
              <p className="!text-sm !text-primary hidden sm:flex whitespace-pre">
                {
                  enrollmentCountText(
                    enrollmentCount,
                    paymentOption,
                    archivedLink,
                  )?.desktopText
                }
              </p>
            </div>
          )}

          <div
            className={`grid grid-cols-1 md:${courseRunsGridColsClass} md:grid-cols-[repeat(auto-fill,minmax(275.5px,1fr))] gap-6`}
          >
            {visibleCourseRuns
              .sort((crA, crB) => {
                if (!crA.start && !crB.start) return 0;
                if (!crA.start) return 1;
                if (!crB.start) return -1;

                const startComparison =
                  new Date(crA.start).getTime() - new Date(crB.start).getTime();

                // If start dates are equal, sort by end date
                if (startComparison === 0) {
                  if (crA.end && crB.end) {
                    return (
                      new Date(crA.end).getTime() - new Date(crB.end).getTime()
                    );
                  }
                  if (crA.end) return -1;
                  if (crB.end) return 1;
                  return 0;
                }

                return startComparison;
              })
              .map((run) => {
                const secondaryText = getCourseRunTileSecondaryText(run);
                if (!hasSecondaryText && secondaryText) {
                  hasSecondaryText = true;
                }
                return (
                  <div key={run.id} className="md:w-[275.5px]">
                    <Card className="p-6 flex bg-white flex-col justify-between">
                      {tagPacingOnTiles && run.pacingType !== null && (
                        <div className="bg-putty-light text-xs max-w-fit mb-4 py-[0.125rem] px-2 rounded self-center text-center">
                          {
                            mapPacingToText[
                              run.pacingType as
                                | "self_paced"
                                | "instructor_paced"
                            ]
                          }
                        </div>
                      )}
                      <div className="mb-5 text-center w-full">
                        <div className="text-base font-bold">
                          {getCourseRunTilePrimaryText(run)}
                        </div>
                        {run.end && (
                          <div className="text-sm">
                            {secondaryText ? (
                              secondaryText
                            ) : hasSecondaryText ? (
                              <br />
                            ) : null}
                          </div>
                        )}
                      </div>
                      {run ? (
                        <div className="w-full">
                          <CourseEnrollButton
                            id="course-run-selector-run"
                            course={course}
                            courseRun={run}
                            emailOptIn={emailOptIn}
                            buttonClass="w-full"
                            category={category}
                            courseSlug={courseSlug}
                          ></CourseEnrollButton>
                        </div>
                      ) : null}
                    </Card>
                  </div>
                );
              })}
          </div>
        </>
      )}

      {hasHiddenRuns && (
        <button className="p-0 mb-3" onClick={toggleRunVisibility}>
          <u className="small">
            {hideExtraRuns ? "More Dates" : "Fewer Dates"}
          </u>
        </button>
      )}

      <div className="flex flex-col mt-4">
        {!hideEmailOptInForCourse.includes(id) &&
          !isUserEnrolled &&
          !isArchived &&
          courseIsEnrollable && (
            <EmailOptIn
              optIn={emailOptIn}
              partners={partners}
              title={title}
              toggleEmailOptIn={toggleEmailOptIn}
            />
          )}
      </div>
    </div>
  );
}
